import Base from './Base.service'
import { store } from '../redux/index.store'
import {
    GENERATE_OTP,
    MATCH_OTP,
    GET_SLOTS_DETAILS,
    SRL_SAMPLE_BOOKING,
    SRL_RESPONSE_SAVE,
    SAVE_SRL_ORDER_STATUS,
    GET_CUSTOMER_OUT,
    GET_CUSTOMER_DETAILS_OUT,
    GET_ORDER_DETAILS_OUT,
    GET_SRL_SAMPLE_STATUS,
    SEND_LOGIN_SMS,
    OTHER_LOGIN_API,
    CUSTOMER_LOGIN_BY_QR,
    UPDATE_DELIVERY_STATUS,
    GET_ORDER_DETAILS_BY_ID,
    CUSTOMER_LOGIN_TRACKING_API,
    GET_SUMMARY,
    GET_STATUS_TRACK,
    GET_CITY_TAT
} from './Slugs'
import CommonAction from '../redux/actions/Common.action';


class OtherService extends Base {
  constructor(props) {
    super(props)
  }

  getStatusTrack(data){
    return this.post(GET_STATUS_TRACK,data);
  }

  customerLogin(data) {
    return this.post(OTHER_LOGIN_API, data);
  }

  generateOtp(data){
    return this.post(GENERATE_OTP,data);
  }
  
  verifyOtp(data){
    return this.post(MATCH_OTP,data);
  }
  getSlotsApi(data){
    return this.post(GET_SLOTS_DETAILS,data);
  }
  createBooking(data){
    return this.post(SRL_SAMPLE_BOOKING,data)
  }
  saveSrlResponse(data){
    return this.post(SRL_RESPONSE_SAVE,data)
  }
  saveResponse(data){

    return this.post(SAVE_SRL_ORDER_STATUS,data)
  }
  getCustomerOut(){
    return this.get(GET_CUSTOMER_OUT);
  }
  sendLoginSMS(data){
    return this.post(SEND_LOGIN_SMS,data);
  }

  getCustomerDetailsOut(data){
    return this.post(GET_CUSTOMER_DETAILS_OUT,data);
  }
  getOrderDetailsOut(data){
    return this.post(GET_ORDER_DETAILS_OUT,data);
  }
  getSRLSamplePickupStatus(data){
    return this.post(GET_SRL_SAMPLE_STATUS,data);
  }
  customerLoginNew(data){
    return this.post(CUSTOMER_LOGIN_BY_QR,data);
  }
  updateDeliveryStatus(data){
    return this.post(UPDATE_DELIVERY_STATUS,data);
  }
  getOrderDetails(data){
    return this.post(GET_ORDER_DETAILS_BY_ID,data);
  }
  customerTrackingLogin(data){
    return this.post(CUSTOMER_LOGIN_TRACKING_API,data);
  }

  getCitytat(){
    return this.get(GET_CITY_TAT);
  }
  getSummary(payload) {
    return new Promise(async resolve => {
      try {
        let response = await this.post(GET_SUMMARY, payload);
        if(response.data.success) {
          if(response.data.data) {
            await store.dispatch(CommonAction.setSummaryDetails(response.data.data))
          }
        }
        console.log(response.data.success, response.data.data, "response11");
        return resolve({ success: true })
      } catch (error) {
        console.log(error, "response error");
        return resolve({ success: false, message: error.message || "Something went wrong." })
      }
    })
  }
}

export default  new OtherService()