import {
    SET_CALL_CENTER,
    CLEAR_STATE,
    SET_SELECTED_DETAILS,
    SET_CALL_CENTER_CUSTOMER
    
    } from '../types/CallCenter.type'
    class CallCenterAction {
      setCallCenter = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        return { type: SET_CALL_CENTER, payload: data }
      }
      setSelectedDetails = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        return { type: SET_SELECTED_DETAILS, payload: data }
      }

      setCallCenterCustomer = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        
        return { type: SET_CALL_CENTER_CUSTOMER, payload: data }
      }
      
      logout = ()=>{
        return { type: CLEAR_STATE, payload: null }
      }
     
      
    }
    export default new CallCenterAction()