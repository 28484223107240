import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  ADMIN_INFO,

} from './Slugs'
class AdminService extends Base {
  constructor(props) {
    super(props)
  }

  getAdminInfo(data) {
    return this.post(ADMIN_INFO,data);
  }

}

export default new AdminService()