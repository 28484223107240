import {
    SET_ADMIN,
    CLEAR_STATE
} from '../types/Admin.type'

const initState = {
    admin: {},
    token: null
}

export default (state = initState, action) => {
    const { type, payload } = action
    console.log(action, 565656);
    switch (type) {
        case SET_ADMIN:
            return Object.assign({}, state, { admin: { ...payload } })
        case CLEAR_STATE:
            return initState;
        default:
            return state
    }
}