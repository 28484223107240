import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  CALL_CENTER_DETAIL,CALL_CENTER_INFO, GET_AUDIT_LIST, POST_INITIATE_RTO
} from './Slugs'
class CallCenterService extends Base {
  constructor(props) {
    super(props)
  }

 
  getCallCenterDetails(data) {
    return this.post(CALL_CENTER_DETAIL,data);
  }
  getCallCenterInfo(data) {
    return this.post(CALL_CENTER_INFO,data);
  }

  getAuditList(payload){
    return this.post(GET_AUDIT_LIST,payload);
  }

  getInitiateRTO(data){

    return this.post(POST_INITIATE_RTO,data);
  }

}

export default new CallCenterService()