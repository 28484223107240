import React, { Component, useState } from 'react';
import { format } from 'date-fns';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import LocationIcon from '../../../assets/Svg/LocationIcon';
import CustomerService from '../../../services/Customer.service';
import OtherService from '../../../services/OtherService';
import CustomerAction from "../../../redux/actions/Customer.action";
import CustomerHeader from "../../../components/layout/CustomerHeader"
import CustomerFooter from "../../../components/layout/CustomerFooter";
import BackButton from '../../../components/Reusable/BackButton';
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import config from '../../../config/emrok.config';
import Loader from '../../../components/Loader/Loader.component';
import Common from '../../../hoc/Common.hoc';
import OrderAction from '../../../redux/actions/Order.action';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class Registration extends Component {
    constructor(props) {
        super(props)

        this.state = {
            registration: this.customerRegModel(),
            doctorName: "",
            selectedFile: null,
            fileUploadError: '',
            imagename: '',
            color: 'red',
            age: null,
            isValidAge: false,
            slots: [],
            currentDate: this.getCurrentDate(),
            isLoading: false,
            allOrders: [],
            isChecked: false,
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
        }
        this.fileInputRef = React.createRef();
    }



    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // static getDerivedStateFromProps(state, props) {
    //     return {
    //         doctorName: props.CommonReducer
    //     }
    // }

    handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            this.setState({ selectedFile: file, fileUploadError: '', imagename: '' }, () => {
                this.handleUpload()
            });
        }
    };

    handleButtonClick = () => {

        // Programmatically trigger the file input click event
        this.fileInputRef.current.click();
    };

    handleUpload = () => {

        const MaxFileSize = 10 * 1024 * 1024; // 10MB in bytes
        // Handle the file upload logic here
        const { selectedFile } = this.state;
        if (selectedFile) {
            // Check if the file size is within the limit
            if (selectedFile.size <= MaxFileSize) {
                // You can use APIs or send the file to the server from here
                let payload = new FormData()
                payload.append("customer_id", this.props.CustomerReducer.customer._id || null)
                payload.append("doctor_id", this.props.CustomerReducer.customer.prescribedDoctor._id || null)
                payload.append("prescription", selectedFile)
                console.log('File to upload:', selectedFile, this.props.CustomerReducer.customer, payload);
                CustomerService.uploadPrescription(payload).then(data => {
                    console.log(data.data.data.filepath, "data !!!!!!!!!!!!");
                    this.setState({ fileUploadError: 'Prescription uploaded', color: "green", imagename: data.data.data.filepath });
                }).catch(err => console.log(err, "err"))
            } else {
                this.setState({ fileUploadError: 'File size exceeds the maximum limit (10MB).' });
            }
        } else {
            this.setState({ fileUploadError: 'Please select a file first.' });
        }
    };

    customerRegModel = (data = { address: {} }) => {
        if (data.address == null) {
            data.address = {};
        }


        const obj = {
            name: data.name ? data.name : "",
            address: {
                country: data.address.country ? data.address.country : "India",
                addressLine1: data.address.addressLine1 ? data.address.addressLine1 : "",
                addressLine2: data.address.addressLine2 ? data.address.addressLine2 : "",
                town: data.address.town ? data.address.town : "",
                state: data.address.state ? data.address.state : "",
                pin: data.address.pin ? data.address.pin : "",
            },
            age: data.age ? data.age : "",
            email: data.email ? data.email : "",
            mobileno: data.mobileno ? data.mobileno : "",
            gender: data.gender ? data.gender : "",
            collection_date: data.collection_date ? data.collection_date : "",
            collection_slot: data.collection_slot ? data.collection_slot : ""
        }
        return { ...obj }
    }

    componentDidMount() {
        CountryService.getCountries().then(data => {
            //console.log(data, "countries")	
            if (data.success) {
                this.setState({ countries: data.countries })
            }
        })
        const customer = this.props.CustomerReducer.customer;
        console.log(this.customerRegModel(customer), customer)

        console.log(customer, "customerDetails");

        const registrationdetails = this.customerRegModel(customer);

        if (registrationdetails) {

            if (registrationdetails.collection_date) {
                const selectedcollectionDate = registrationdetails.collection_date;
                const pin = customer.address.pin;
                const formattedDate = format(new Date(selectedcollectionDate), 'dd-MMM-yyyy');
                const slottime = formattedDate;

                let body = {
                    cityId: "",
                    slotdate: slottime ? slottime : "07-AUG-2023",
                    pincode: pin ? pin.toString() : "400063",
                    source: "HA"
                };

                OtherService.getSlotsApi(body).then(res => {


                    console.log(res, "slotsdetails")
                    if (res && res.data && res.data.success) {

                        console.log(res.data.data.RSP_MSG, "slotdetails");
                        if (res.data.data.RSP_MSG) {
                            const allSlots = res.data.data.RSP_MSG;

                            const has_12_pm = allSlots.filter(item => item.SLOTS.includes("12:00 PM"))
                            const has_12_am = allSlots.filter(item => item.SLOTS.includes("12:00 AM"))

                            const pm_array = allSlots.filter(item => (item.SLOTS.includes("PM") && !item.SLOTS.includes("12:00 PM")));
                            const am_array = allSlots.filter(item => (item.SLOTS.includes("AM") && !item.SLOTS.includes("12:00 AM")));

                            let new_slots = []

                            if (has_12_am.length > 0) {

                                new_slots = has_12_am.concat(am_array)
                            } else {
                                new_slots = am_array
                            }

                            if (has_12_pm.length > 0) {

                                new_slots = new_slots.concat(has_12_pm, pm_array)
                            } else {
                                new_slots = new_slots.concat(pm_array)
                            }





                            const currentDate = moment().format('yyyy-MM-DD');
                            const formattedDate = format(new Date(selectedcollectionDate), 'yyyy-MM-dd');

                            console.log(currentDate, "currentDate");
                            console.log(formattedDate, "formattedDate");

                            const datesAreEqual = moment(currentDate).isSame(formattedDate, 'day');
                            console.log(datesAreEqual, "datesAreEqual");
                            const currentTime = moment();
                            // const currentTime = moment(`${currentDate} 10:00 AM`, 'YYYY-MM-DD hh:mm A');
                            if (datesAreEqual) {
                                // Filter slots greater than current time on the current date
                                const filteredSlots = new_slots.filter(slot => {
                                    const slotTime = moment(`${currentDate} ${slot.SLOTS}`, 'YYYY-MM-DD hh:mm A');
                                    return slotTime.isAfter(currentTime);
                                });

                                this.setState({
                                    slots: filteredSlots ? filteredSlots : []
                                })


                                console.log(filteredSlots, "filteredSlots");

                                console.log('The two dates are equal123456.',);
                            } else {
                                this.setState({
                                    slots: new_slots ? new_slots : []
                                })

                                console.log('The two dates are not equal.123456');
                            }

                        } else {

                        }
                        // this.setState({ slotdetails: res })
                    }
                }).catch(err => {

                    console.log(err)
                });
            }

        }
        this.setState({
            registration: customer ? this.customerRegModel(customer) : this.customerRegModel(),
            doctorName: customer.prescribedDoctor ? customer.prescribedDoctor.name : ""
        }, async () => {
            let registration = await this.state.registration
            registration["address"]["country"] = "India"
            await this.setState({ registration })
            // let country = await this.state.registration.address.country || null	
            // console.log(country);	
            // if (country) {	
            // let countryObj = this.state.countries.find(i => i.name === "India")	
            // alert(JSON.stringify(countryObj))	
            setTimeout(() => {
                this.populateStates("IN")
                // this.populateStates(countryObj.isoCode)	
            }, 500);
            // }	
        })
    }
    changeVal = async (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const registration = { ...this.state.registration };
        registration[name] = value;
        this.setState({ registration: registration });
    }


    componentDidUpdate(prevProps) {
        if (prevProps.CustomerReducer.customer !== this.props.CustomerReducer.customer) {
            const customer = this.props.CustomerReducer.customer;
            this.setState({
                registration: customer ? this.customerRegModel(customer) : this.customerRegModel(),
                doctorName: customer.prescribedDoctor ? customer.prescribedDoctor.name : ""
            })
        }
    }


    changeAddr = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if (name == "pin") {
            value = event.target.value.replace(/\D/g, '');
        }

        const address = { ...this.state.registration.address };
        address[name] = value;
        this.setState({ registration: { ...this.state.registration, address: { ...address } } });
    }

    next = () => {

        const addressLine1 = this.state.registration.address.addressLine1;
        const country = this.state.registration.address.country == "India" ? "India" : "India";
        const state = this.state.registration.address.state;
        const pin = this.state.registration.address.pin;
        const town = this.state.registration.address.town;
        const name = this.state.registration.name;
        const age = this.state.registration.age;
        const gender = this.state.registration.gender;
        const collection_date = this.state.registration.collection_date;
        const collection_slot = this.state.registration.collection_slot;
        const email = this.state.registration.email;

        console.log('dvdfbbgnghmnhjgfkl>>>>>>>>>>>', this.state)
        console.log('collection slot', collection_slot)

        if (email && email !== "") {
            if (!this.props.hoc.verifyEmail(email)) {
                this.props.hoc.customAlert('Please provide a valid email.', false)
                return false
            }
        }
        if (!name && name == "") {
            this.props.hoc.customAlert('Name is required.', false)
            return false
        }
        else if (!age && age == "") {
            this.props.hoc.customAlert('Age is required.', false)
            return false
        }
        else if (!gender && gender == "") {
            this.props.hoc.customAlert('Gender is required.', false)
            return false
        }
        else if (age < 18) {
            this.props.hoc.customAlert("Age must be greater than or equal to 18", false)
        }
        else if (!addressLine1 && addressLine1 == "") {
            this.props.hoc.customAlert('Address Line 1 is required.', false)
            return false
        }
        else if (!country && country == "") {
            this.props.hoc.customAlert('Country is required.', false)
            return false
        }
        else if (!state && state == "") {
            this.props.hoc.customAlert('State is required.', false)
            return false
        }
        else if (!pin && pin == "") {
            this.props.hoc.customAlert('Pincode is required.', false)
            return false
        }
        else if (pin && pin.toString().length < 6) {
            this.props.hoc.customAlert('Pincode must be 6 digits.', false)
            return false
        }
        else if (!town && town == "") {
            this.props.hoc.customAlert('Town is required.', false)
            return false
        }
        else if (!collection_date && collection_date == "") {
            this.props.hoc.customAlert('Collection date is required.', false)
            return false
        }
        else if (!collection_slot && collection_slot == "") {
            this.props.hoc.customAlert('Collection slot is required.Please select a slot.', false)
            return false
        } else if (this.props.CustomerReducer.consentValue == false || this.props.CustomerReducer.consentValue == null) {

            this.props.hoc.customAlert('Please check consent checkbox.', false)
            return false
        }
        else {

            console.log('test 135465874>>>>>>>>>>>>>>>>>>>>>')
            const { registration } = this.state;
            const id = this.props.CustomerReducer.customer._id;
            const doctorid = this.props.CustomerReducer.customer['prescribedDoctor']._id;
            console.log(doctorid, "customer details")
            let detailsCustomer = {
                customer_id: id,
                doctor_id: doctorid
            };
            console.log(registration, id, 8989);
            CustomerService.updateCustomer({ registration, id }).then(res => {
                if (res && res.data && res.data.success && res.data.data) {
                    let customer = res.data.data.customer
                    if (customer["prescribedDoctor"] && Array.isArray(customer["prescribedDoctor"])) {
                        customer["prescribedDoctor"] = customer["prescribedDoctor"].length ? customer["prescribedDoctor"][0] : null
                    }


                    OtherService.getCustomerOut().then(res => {
                        // //console.log(res, 7878);

                        if (res && res.data && res.data.success) {
                            //console.log(res, 123456);
                        }

                    }).catch(err => {
                        //console.log(err)
                    })
                    let sendData = {
                        event: "AFTER_LOGIN",
                        customer_name: customer.name,
                        customer_code: customer.customer_code,
                        mobileno: customer.mobileno
                    }

                    OtherService.sendLoginSMS(sendData).then(res => {
                        // //console.log(res, 7878);

                        if (res && res.data && res.data.success) {
                            //console.log(res, 123456);
                        }

                    }).catch(err => {
                        //console.log(err)
                    })


                    // OtherService.getCustomerDetailsOut(detailsCustomer).then(res => {
                    //     // //console.log(res, 7878);

                    //     if (res && res.data && res.data.success) {
                    //         console.log(res, "response from customer out");
                    //     }

                    // }).catch(err => {
                    //     //console.log(err)
                    // })


                    this.props.setCustomer(customer);
                    this.props.navigate('/customer/place-order')
                }
            }).catch(e => {
                console.log(e)
            })
        }




        // if (addressLine1 == '' || country == '' || state == '' || town == '' || name == '' || age == '' || gender == '' || pin == '' || collection_date == '' || collection_slot == '') {
        //     alert('Please fill up required (*) fields')
        //     return false;

        // } else if (age <= 17) {

        //     alert('Age Must Be More than or Equal to 18')
        //     return false;

        // } else {


        // }


    }

    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {
                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.registration.address.state || null
                        console.log(_state, this.state.states, "jhgjhgjhg");
                        if (_state) {
                            let stateObj = null
                            for (let i = 0; i < this.state.states.length; i++) {
                                if (this.state.states[i].name === _state) {
                                    stateObj = this.state.states[i]
                                }
                            }
                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })
                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))
                }
                // }, 2000);
            })
        }
    }

    handleClick = () => {

        const customer = this.props.CustomerReducer.customer;

        if (customer) {

            let details = {
                customer_id: customer._id
            }
            try {
                CustomerService.getOrders(details).then(res => {

                    console.log(res, "orderresponse");
                    if (res && res.data && res.data.success && res.data.data) {


                        if (res.data.data.order.length > 0) {

                            console.log("all orders", res.data.data.order);
                            this.props.navigate('/customer/orders')
                            // this.props.navigate('/customer/track-order')
                            // this.props.saveAllOrder(res.data.data.order);
                            // this.setState({ orderList: res.data.data.order });

                            // const { orderList } = this.state;
                            // console.log(orderList, "all orders myownn");

                        } else {

                            this.props.hoc.customAlert('You dont have any order.', false);
                        }

                        console.log(res.data.data.order, "order details");

                    } else {

                        this.props.hoc.customAlert('You dont have any order.', false);
                    }

                })
            } catch (error) {
                console.log(error);
            }


        } else {

        }



        // const customer = this.props.CustomerReducer.customer;

        // if (customer) {

        //     let details = {
        //         customer_id: customer._id,
        //         app_name: "myownn"
        //     }
        //     try {
        //         CustomerService.getOrders(details).then(res => {

        //             console.log(res,"orderresponse");
        //             if (res && res.data && res.data.success && res.data.data) {


        //                 if (res.data.data.order.length > 0) {

        //                     console.log("all orders",res.data.data.order);
        //                     // this.props.navigate('/customer/track-order')
        //                     this.props.saveAllOrder(res.data.data.order);
        //                     this.props.navigate('/customer/orders')
        //                 } else {

        //                     this.props.hoc.customAlert('You dont have any order.', false);
        //                 }

        //                 console.log(res.data.data.order, "order details");

        //             } else {

        //                 this.props.hoc.customAlert('You dont have any order.', false);
        //             }

        //         })
        //     } catch (error) {
        //         console.log(error);
        //     }


        // } else {

        // }

        // console.log(customer, "customer details");


    }

    handleDateTimeChange = (date) => {

        //  alert(date);
        const formatedDate = moment(date).format('YYYY-MM-DD');
        // const formattedDate = moment(dateString).format('ddd MMM D YYYY HH:mm:ss [GMT]Z (zz)');
        // alert(formatedDate)
       
        // const name = event.target.name;
        const value = formatedDate;
        const registration = { ...this.state.registration };
        registration['collection_date'] = value;
        this.setState({ registration: registration });

        // const { registration} = this.state;
        const { address } = registration;
        const { pin } = address;


        console.log(pin);
        if (pin) {
            const newDateTime = date;
            console.log(newDateTime, "newdatetime");
            // Convert date format
            const formattedDate = format(new Date(newDateTime), 'dd-MMM-yyyy');
            const slottime = formattedDate;
            const data = { slottime, pin };

            console.log(data,"dataval");

            let body = {
                cityId: "",
                slotdate: slottime ? slottime : "07-AUG-2023",
                pincode: pin ? pin.toString() : "400063",
                source: "HA"
            };

            console.log(body, "details");
            this.setState({ isLoading: true });
            OtherService.getSlotsApi(body).then(res => {


                console.log(res, "slotsdetails")
                if (res && res.data && res.data.success) {
                    console.log(this.state.isLoading, "isloading start");
                    console.log(res.data.data.RSP_MSG, "slotdetails");
                    if (res.data.data.RSP_MSG.length > 0) {
                        console.log(res, "slotsdetails123");
                        const allSlots = res.data.data.RSP_MSG;

                        const has_12_pm = allSlots.filter(item => item.SLOTS.includes("12:00 PM"))
                        const has_12_am = allSlots.filter(item => item.SLOTS.includes("12:00 AM"))

                        const pm_array = allSlots.filter(item => (item.SLOTS.includes("PM") && !item.SLOTS.includes("12:00 PM")));
                        const am_array = allSlots.filter(item => (item.SLOTS.includes("AM") && !item.SLOTS.includes("12:00 AM")));

                        let new_slots = []

                        if (has_12_am.length > 0) {

                            new_slots = has_12_am.concat(am_array)
                        } else {
                            new_slots = am_array
                        }

                        if (has_12_pm.length > 0) {

                            new_slots = new_slots.concat(has_12_pm, pm_array)
                        } else {
                            new_slots = new_slots.concat(pm_array)
                        }

                        this.setState({ isLoading: false });


                        const currentDate = moment().format('yyyy-MM-DD');
                        const formattedDate = format(new Date(newDateTime), 'yyyy-MM-dd');

                        console.log(currentDate, "currentDate");
                        console.log(formattedDate, "formattedDate");

                        const datesAreEqual = moment(currentDate).isSame(formattedDate, 'day');
                        console.log(datesAreEqual, "datesAreEqual");
                        const currentTime = moment();
                        // const currentTime = moment(`${currentDate} 10:00 AM`, 'YYYY-MM-DD hh:mm A');
                        if (datesAreEqual) {
                            // Filter slots greater than current time on the current date
                            const filteredSlots = new_slots.filter(slot => {
                                const slotTime = moment(`${currentDate} ${slot.SLOTS}`, 'YYYY-MM-DD hh:mm A');
                                return slotTime.isAfter(currentTime);
                            });

                            this.setState({
                                slots: filteredSlots ? filteredSlots : []
                            })


                            console.log(filteredSlots, "filteredSlots");

                            console.log('The two dates are equal123456.',);
                        } else {
                            this.setState({
                                slots: new_slots ? new_slots : []
                            })

                            console.log('The two dates are not equal.123456');
                        }



                        // // Filter slots greater than current time on the current date
                        // const filteredSlots = new_slots.filter(slot => {
                        //   const slotTime = moment(`${currentDate} ${slot.SLOTS}`, 'YYYY-MM-DD hh:mm A');
                        //   return slotTime.isAfter(currentTime);
                        // });

                        // console.log(filteredSlots,"filteredSlots");


                        // this.setState({
                        //     slots: filteredSlots ? filteredSlots : []
                        // })

                        console.log(this.state.isLoading, "isloading end");

                    } else {
                        this.setState({ isLoading: false });
                    }
                    // this.setState({ slotdetails: res })
                }
            }).catch(err => {
                this.setState({ isLoading: false });
                console.log(err)
            });


        } else {
            this.props.hoc.customAlert('Please add pincode.', false)
            // alert("Please put pincode.");
        }



        // console.log(newDateTime);
        // setSelectedDateTime(newDateTime);
        //You can perform other actions with the newDateTime value
    }

    handleConsentCheckbox = () => {

        const currentVal = !this.state.isChecked

        this.setState({ isChecked: currentVal });
        this.props.setConsentCheckbox(currentVal);

    }

    handleKeyDown = (e) => {
        // Prevent any keyboard input from changing the value
        e.preventDefault();
    };


    render = () => {
        const { fileUploadError, color, registration, imagename, countries, states, towns, isLoading, showTowns } = this.state;
        const { name, address, age, email, gender, mobileno, collection_date, collection_slot } = registration;
        console.log(collection_date,"collecrtiondate");

        const formatedCollectionDate = collection_date!=""?new Date(`${collection_date}T00:00:00.000Z`):"";
        console.log(formatedCollectionDate,"formatedCollectionDate");
        const { country, addressLine1, addressLine2, pin, town, state } = address
        const { doctorName, slots } = this.state;
        const { isChecked } = this.state;
        const isCheckedConsent = this.props.CustomerReducer.consentValue;
        console.log(isCheckedConsent, "isCheckedConsent")
        console.log(registration, '>>>>>>>>>>>>>>>>>')
        return (
            <div>

                {/*myown-login-part-start-----*/}
                {/* <section className="regstr-form">
                    <div className="container">
                        <CustomerHeader />
                        <div className="reg-frm-dta">
                            <div className="reg-text-ft"><h2>Register Form</h2></div>
                        </div>
                        <form className="mt-3 registration-form">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Name <span className='requiredFld'> * </span></label>
                                    <input type="text" value={name} name="name" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                </div>
                                <div className="col-md-6 mb-3 for-apper-pt">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Age <span className='requiredFld'> * </span></label>
                                    <input type="number" value={age} onInput={(e) => e.target.value = e.target.value.slice(0, 2)} onChange={this.changeVal} name='age' className="form-control name-pt-ft" placeholder="Select age" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor className="form-label cls-gndr lvl-clr-hd">Gender <span className='requiredFld'> * </span></label>
                                    <select className="ml-feml-itm" value={gender} onChange={(event) => this.changeVal(event)} name="gender">
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="others">Others</option>
                                    </select>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Mobile Number <span className='requiredFld'> * </span>
                                    </label>
                                    <input type="text" value={mobileno} disabled onChange={(event) => this.changeVal(event)} name="mobileno" id className="form-control name-pt-ft" placeholder />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Email</label>
                                    <input type="text" value={email} name="email" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Prescribing Doctor <span className='requiredFld'> * </span></label>
                                    <input type="text" value={doctorName} disabled id className="form-control name-pt-ft" placeholder />
                                </div>
                            </div>
                            <div className=" mt-4 mb-4">
                                <input
                                    type="file"
                                    ref={this.fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={this.handleFileChange}
                                />
                                <button type="button" className="upld-prscrbtn-pt" id onClick={this.handleButtonClick}>
                                    Upload Your Prescription
                                </button>
                                <img src={`${config.serviceUrl}/prescription/${imagename}`} alt="Product-1" className='img_resize'/>
                                {fileUploadError && <div style={{ color, textAlign: 'right', marginRight: 20 }}>{fileUploadError}</div>}
                            </div>
                            <div className="reg-frm-dta align-items-center">
                                <div className="reg-text-ft"><h3>Product Delivery Address</h3></div>
                                <div className="know-more-text mb-0">
                                    <a href="javascript:void(0);" className="kow-more-link text-underline">Know more</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Address Line -1 <span className='requiredFld'> * </span></label>
                                    <input type="text" value={addressLine1} onChange={(event) => this.changeAddr(event)} name='addressLine1' id className="form-control name-pt-ft" placeholder />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-10 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Address Line -2</label>
                                    <input type="text" value={addressLine2} onChange={(event) => this.changeAddr(event)} name='addressLine2' id className="form-control name-pt-ft" placeholder />
                                </div>
                                <div className="col-md-2 mb-3 for-apper-pt">
                                    {/* <div className="my-locatn">
                                        <button type className="use-lctn-part" onclick>
                                            <LocationIcon className="ionicon" />
                                            Use My Location
                                        </button>
                                    </div> */}
                {/* <label htmlFor className="form-label lvl-clr-hd">Pin <span className='requiredFld'> * </span></label>
                                    <input type='number' onInput={(e) => e.target.value = e.target.value.slice(0, 6)} value={pin} onChange={(event) => this.changeAddr(event)} name="pin" id className="form-control name-pt-ft" placeholder />
                                </div> */}
                {/* </div> */}
                {/* <div className="row">
                                <div className="col-md-4 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">City/Town <span className='requiredFld'> * </span></label>
                                    <input type="text" value={town} onChange={(event) => this.changeAddr(event)} name="town" id className="form-control name-pt-ft" placeholder />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State <span className='requiredFld'> * </span></label>
                                    <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Country <span className='requiredFld'> * </span></label>
                                    <input type="text" value={country} onChange={(event) => this.changeAddr(event)} name="country" id className="form-control name-pt-ft" placeholder />
                                </div>
                            </div> */}
                {/* <div className="row mt-4">
                                <div className="end-bck-btn-pt">
                                    <div className> */}
                {/* <BackButton className="bck-btn-crcl" /> */}
                {/* <button type="button" className="bck-btn-crcl" id onclick>
                                            Back
                                        </button> */}
                {/* </div>
                                    <div className>
                                        <button type="button" className="next-btn-crcl" id onClick={() => this.next()}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div> */}
                {/* </section> */}
                <section className="regstr-form">
                    <div className="container">
                        <CustomerHeader />
                        {/* <div className="row">
                            <div className="w-100 logo-top-box mb-0">
                                <div className="d-flex justify-content-between align-items-center">
                                <div className="logo-wrap">
                                    <a href="index.html">
                                    <img src="images/logo.png" alt="logo" />
                                    </a>
                                </div>
                                <div>
                                    <button type="submit" className="btn-logout">
                                    <svg
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        className="logout-icon"
                                        d="M14.6666 7.99992H6.74992M12.9999 10.4999L15.4999 7.99992L12.9999 5.49992M8.83325 3.83325V2.99992C8.83325 2.55789 8.65766 2.13397 8.3451 1.82141C8.03254 1.50885 7.60861 1.33325 7.16659 1.33325H2.99992C2.55789 1.33325 2.13397 1.50885 1.82141 1.82141C1.50885 2.13397 1.33325 2.55789 1.33325 2.99992V12.9999C1.33325 13.4419 1.50885 13.8659 1.82141 14.1784C2.13397 14.491 2.55789 14.6666 2.99992 14.6666H7.16659C7.60861 14.6666 8.03254 14.491 8.3451 14.1784C8.65766 13.8659 8.83325 13.4419 8.83325 12.9999V12.1666"
                                        stroke=""
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        />
                                    </svg>
                                    Logout
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-6 regfrm-bag-image d-lg-none py-5">
                                <img src="images/register-bg-image.png" className="" alt="" />
                            </div>
                        </div>
                        <div className="reg-frm-dta">
                            <div className="reg-text-ft">
                                <h2>Register Form</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <form className="mt-3 registration-form">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor className="form-label lvl-clr-hd">Name <span className='requiredFld'> * </span></label>
                                            <input type="text" value={name} name="name" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Age <span className='requiredFld'> * </span></label>
                                            <input type="number" value={age} onInput={(e) => e.target.value = e.target.value.slice(0, 2)} onChange={this.changeVal} name='age' className="form-control name-pt-ft" placeholder="Select age" />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor className="form-label cls-gndr lvl-clr-hd">Gender <span className='requiredFld'> * </span></label>
                                            <select className="ml-feml-itm" value={gender} onChange={(event) => this.changeVal(event)} name="gender">
                                                <option value="" disabled selected hidden>Please Select</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="others">Others</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Mobile Number <span className='requiredFld'> * </span>
                                            </label>
                                            <input type="text" value={mobileno} disabled onChange={(event) => this.changeVal(event)} name="mobileno" id className="form-control name-pt-ft" placeholder />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="" className="form-label lvl-clr-hd">Email
                                            </label>
                                            <input type="text" value={email} onChange={(event) => this.changeVal(event)} name="email" id className="form-control name-pt-ft" placeholder />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label
                                                htmlFor="disabledTextInput"
                                                className="form-label lvl-clr-hd"
                                            >
                                                Prescribing Doctor
                                            </label>
                                            <input type="text" value={doctorName} disabled id className="form-control name-pt-ft" placeholder />
                                        </div>

                                    </div>
                                    <div className="reg-frm-dta align-items-center mt-5">
                                        <div className="reg-text-ft">
                                            <h3>Residential Address for Blood Collection</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor className="form-label lvl-clr-hd">Address Line -1 <span className='requiredFld'> * </span></label>
                                            <input type="text" value={addressLine1} onChange={(event) => this.changeAddr(event)} name='addressLine1' id className="form-control name-pt-ft" placeholder />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6 regfrm-bag-image d-none d-lg-block">
                                <img src={`${config.serviceUrl}/images/myownn/register-bg-image.png`} className="img_resize" alt="Product-1" />
                                {/* <img src={`${config.serviceUrl}/prescription/${imagename}`} alt="Product-1" className='img_resize'/> */}
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-10 mb-3">
                                        <label htmlFor className="form-label lvl-clr-hd">Address Line -2</label>
                                        <input type="text" value={addressLine2} onChange={(event) => this.changeAddr(event)} name='addressLine2' id className="form-control name-pt-ft" placeholder />
                                    </div>
                                    <div className="col-md-2">
                                        {/* <div className="my-locatn">
                                <button type="" className="use-lctn-part" onclick="">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ionicon"
                                    viewBox="0 0 512 512"
                                    >
                                    <path
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={48}
                                        d="M256 96V56M256 456v-40"
                                    />
                                    <path
                                        d="M256 112a144 144 0 10144 144 144 144 0 00-144-144z"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeMiterlimit={10}
                                        strokeWidth={32}
                                    />
                                    <path
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={48}
                                        d="M416 256h40M56 256h40"
                                    />
                                    </svg>
                                    Use My Location
                                </button>
                                </div> */}
                                        <label htmlFor className="form-label lvl-clr-hd">Pin <span className='requiredFld'> * </span></label>
                                        <input type='text' onInput={(e) => e.target.value = e.target.value.slice(0, 6)} value={pin} onChange={(event) => this.changeAddr(event)} name="pin" id className="form-control name-pt-ft" placeholder />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Country <span className='requiredFld'> * </span></label>
                                        {
                                            countries.length &&
                                            <Dropdown
                                                items={countries}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        let _registration = this.state.registration
                                                        _registration.address["country"] = item.name
                                                        _registration.address["state"] = ""
                                                        _registration.address["town"] = ""
                                                        this.setState({ registration: _registration })
                                                        //console.log(item, "item");
                                                        this.populateStates(item.isoCode)
                                                    }
                                                }}
                                                value={country}
                                                isDisabled={true}
                                            />
                                        }
                                        {/* <input type="text" value={country} onChange={(event) => this.changeAddr(event)} name="country" id className="form-control name-pt-ft" placeholder disabled /> */}
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State <span className='requiredFld'> * </span></label>
                                        {
                                            states.length ?
                                                <Dropdown
                                                    items={states}
                                                    onSelect={(item) => {
                                                        if (item) {
                                                            let _registration = this.state.registration
                                                            _registration.address["state"] = item.name
                                                            _registration.address["town"] = ""
                                                            this.setState({ registration: _registration })
                                                            //console.log(item, "item");
                                                            this.populateTowns(item.countryCode, item.isoCode)
                                                        }
                                                    }}
                                                    value={state}
                                                /> :
                                                <Dropdown
                                                    items={states}
                                                    value={state}
                                                />
                                        }
                                        {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor className="form-label lvl-clr-hd">City/Town <span className='requiredFld'> * </span></label>
                                        {
                                            towns.length ?
                                                <Dropdown
                                                    items={towns}
                                                    onSelect={(item) => {
                                                        if (item) {
                                                            let _registration = this.state.registration
                                                            _registration.address["town"] = item.name
                                                            this.setState({ registration: _registration })
                                                            //console.log(item, "item")
                                                        }
                                                    }}
                                                    value={town}
                                                /> :
                                                <Dropdown
                                                    items={towns}
                                                    value={town}
                                                />
                                        }
                                        {/* <input type="text" value={town} onChange={(event) => this.changeAddr(event)} name="town" id className="form-control name-pt-ft" placeholder /> */}
                                    </div>



                                    <div className="col-md-6">
                                        <label htmlFor="" className="form-label lvl-clr-hd">
                                            Blood Collection (Date)<span className='requiredFld'> * </span>
                                        </label>
                                        {/* <input
                                            type="date"
                                            className="blood-camp-pt date-picker-icon"
                                            placeholder="date slot"
                                            min={this.state.currentDate}
                                            value={collection_date}
                                            name='collection_date'
                                            onKeyDown={this.handleKeyDown}
                                            onChange={(event) => this.handleDateTimeChange(event)}
                                        /> */}
                                        <div>
                                        <DatePicker
                                             className="blood-camp-pt date-picker-icon"
                                             onKeyDown={this.handleKeyDown}
                                             selected={formatedCollectionDate}
                                            onChange={this.handleDateTimeChange}
                                            minDate={new Date()} // Set the minimum date here
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText=""
                                        />
                                        </div>
                                        
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="" className="form-label lvl-clr-hd">
                                            Blood Collection (time Slot)<span className='requiredFld'> * </span>
                                        </label>
                                        {/* <input
                                    type="time"
                                    className="blood-camp-pt"
                                    placeholder="date slot"
                                /> */}

                                        <select className="ml-feml-itm slot-option" value={collection_slot} onChange={(event) => this.changeVal(event)} name="collection_slot">

                                            {slots.map(item => (
                                                <option value={item.SLOTS}>
                                                    {item.SLOTS}
                                                </option>
                                            ))}
                                            {/* <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="others">Others</option> */}

                                        </select>
                                    </div>
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">

                                        {slots ? "" : <div style={{ color, textAlign: 'right', marginRight: 20 }}>There is no slots in this pincode.</div>}
                                        {isLoading ? <Loader /> : ""}
                                    </div>
                                    <div className="row mt-4">
                                        <div className='col-md-1 checkboxstyle'>
                                            <input type="checkbox" className='form-label' onChange={this.handleConsentCheckbox} checked={isCheckedConsent} />
                                        </div>
                                        <div className='col-md-10 consenttext' >
                                            <p>I consent to the sharing of my personal data for the purpose of purchasing medicinal products</p>
                                        </div>
                                    </div>

                                    <div className="foot-wse-btn-pt mt-4 mb-4">
                                        <div className="foot-info-fdbk">
                                            <div>
                                                <a href={config.knowMoreLink} className="knw-mr-track" target="_blank" rel="noopener noreferrer">
                                                    Know more
                                                </a>
                                            </div>
                                        </div>

                                        <div className="footer-bottom-btn-group">
                                            <button
                                                type="button"
                                                className="back-to-main me-3"
                                                onClick={this.handleClick}

                                            >
                                                Order History
                                            </button>
                                            <button type="button" className="next-btn-crcl" id onClick={() => this.next()}>
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*myown-login-part-end-----------*/}
                <CustomerFooter />
            </div>
        )

    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer)),
        saveAllOrder: (allOrders) => dispatch(OrderAction.saveAllOrder(allOrders)),
        setConsentCheckbox: (consentValue) => dispatch(CustomerAction.setConsentCheckbox(consentValue))
    }
}

const mapStateToProps = (state) => {
    return {
        // dispatching plain actions
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
        OrderReducer: state.OrderReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(Registration)))