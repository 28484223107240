import axios from 'axios'
import config from '../config/emrok.config';
import { store } from "../redux/index.store";
import CommonAction from '../redux/actions/Common.action';
class Base {
  constructor() {
    this.http = null
    this.#init()
  }

  get(url) {
    return this.http.get(url);
  }

  post(url, data) {
      return this.http.post(url, data);
  }

  put(url, data) {
    return this.http.put(url, data);
  }

  #init = async () => {

    let header = {
      "x-api-token": config.xApiToken,
      "app-name":"myownn"
    }

    this.http = await axios.create({
      baseURL: config.serviceUrl,
      headers: header
    })

    // Request interceptors for API calls
    // this.http.interceptors.request.use(
    //   config => {
    //     const { CommonReducer } = store.getState();
    //     const token = CommonReducer.token;
    //     config.headers['Authorization'] = `Bearer ${token}`;
    //     return config;
    //   },
    //   error => {
    //     return Promise.reject(error);
    //   }
    // );

        // Request interceptors for API calls
        this.http.interceptors.request.use(
          data => {
            const { CommonReducer } = store.getState();
            const token = CommonReducer.token;
            data.headers['Authorization'] = `Bearer ${token}`;
            if (data) {
              if (data.params) {
                if (data.params.hideLoader) {
                  if (!data.params.hideLoader) {
                    this.showLoader()
                  }
                }
                else {
                  this.showLoader()
                }
              }
              else {
                this.showLoader()
              }
            }
            else {
              this.showLoader()
            }
            return data;
          },
          error => {
            this.hideLoader()
            return Promise.reject(error);
          }
        );
    
        //response interceptor
        this.http.interceptors.response.use(response => {
          this.hideLoader()
          return response;
        }, (error) => {
          this.hideLoader()
          return Promise.reject(error)
        });
      }
    
      showLoader() {
        store.dispatch(store.dispatch(CommonAction.getLoaderAction(true)))
      }
    
      hideLoader() {
        store.dispatch(store.dispatch(CommonAction.getLoaderAction(false)))
      }

    


    // //request interceptor
    // this.http.interceptors.request.use(data => {
    //   this.showLoader()
    //   return data
    // }, (error) => {
    //   this.hideLoader()
    //   return Promise.reject(error)
    // })

    // //response interceptor
    // this.http.interceptors.response.use(response => {
    //   this.hideLoader()
    //   return response;
    // }, (error) => {
    //   this.hideLoader()
    //   return Promise.reject(error)
    // });
  }



export default Base