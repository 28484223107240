import React from "react";
import { withRouter } from "../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../hoc/Common.hoc";
import CustomerFooter from "../../components/layout/CustomerFooter";
import moment from "moment";
import CallCenterHeader from "../../components/layout/CallCenter/CallCenterHeader";
import AdminService from "../../services/Admin.service";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CallCenterService from "../../services/CallCenter.service";
import { ExportToExcel } from "../../components/Excel/ExportToExcel";
class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            info: null,
            auditList: [],
            exportData: null,
            fileName: "Audit_Trial",
            searchMonth: "",
            addressField: [
                { name: 'country' },
                { name: 'addressLine1' },
                { name: 'addressLine2' },
                { name: 'town' },
                { name: 'state' },
                { name: 'pin' }
            ]
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            admin: props._id || null,
            summaryDetails: props.summaryDetails || null,
        }
    }

    componentDidMount() {
        const admindetails = this.props.admin;
        const _id = admindetails._id;
        let details = {
            _id: _id
        };

        if (admindetails) {


            AdminService.getAdminInfo(details).then(data => {
                // console.log(data.data, "callcenter info");

                if (data.data.success && data.data.data.data !== '') {
                    // console.log(data.data.data.data[0], "okkkkk");
                    this.setState({ info: data.data.data.data[0] });
                    // }

                }

            }).catch(err => {

                console.log(err, "err")
            })

            this.getAuditList();

            const currentDate = new Date(); // Get the current date
            const currentYear = currentDate.getFullYear(); // Get the current year
            const currentMonth = currentDate.getMonth() + 1; // Get the current month (Note: Months are zero-based, so add 1)
            const finalText = currentYear + "-" + currentMonth;
            this.setState({ searchMonth: finalText });

            // console.log(currentYear+"-"+currentMonth,1000)

        } else {


        }
    }

    getAuditList = (payload = null) => {

        // console.log(payload, "852415")

        this.setState({ auditList: [] }, () => {
            CallCenterService.getAuditList({ type: "admin", searchMonth: payload?.searchMonth }).then(data => {
                // console.log(data.data, "audit details 2");

                if (data.data.success && data.data.data.response !== '') {
                    // console.log(data.data.data.response, "okkkkk12");

                    // this.getDoctors(data.data.data.callcenterdetails);
                    const formatedData = this.formatData(data.data.data.response);
                    // console.log(formatedData, "formatedData")
                    this.setState({ exportData: formatedData });
                    this.setState({ auditList: data.data.data.response });
                }

            }).catch(err => {

                console.log(err, "err")
            })
        });
    }

    formatData(auditdata) {
        let formatedArr = [];
        const { addressField } = this.state;
        for (let i = 0; i < auditdata.length; i++) {

            for (let j = 0; j < auditdata[i].data.length; j++) {

                let changeField = "";

                if (auditdata[i].data[j].action_type == "Modify") {

                    // console.log(auditdata[i].data[j].change_field, "123456")
                    const foundItem = addressField.find((item) => item.name === auditdata[i].data[j].change_field);

                    // console.log(foundItem, "foundItem");

                    if (foundItem) {
                        changeField = auditdata[i].data[j].customer.address[auditdata[i].data[j].change_field]
                        // console.log(`${itemToCheck} exists in the array.`);
                    } else {
                        changeField = auditdata[i].data[j].customer[auditdata[i].data[j].change_field]
                        // console.log(`${itemToCheck} does not exist in the array.`);
                    }
                }

                formatedArr.push({
                    "User Name": auditdata[i].data[j].user ? auditdata[i].data[j].user.name : "",
                    "User Number": auditdata[i].data[j].user ? auditdata[i].data[j].user.mobileno : "",
                    "Customer Name": auditdata[i].data[j].customer ? auditdata[i].data[j].customer.name : "",
                    "Customer ID": auditdata[i].data[j].customer ? auditdata[i].data[j].customer.customer_code : "",
                    "Doctor name": auditdata[i].data[j].doctor ? auditdata[i].data[j].doctor.name : "",
                    "Field Changed": auditdata[i].data[j].change_field,
                    "Old Record": auditdata[i].data[j].customer_old_value,
                    "New Record": changeField,
                    "Action Type": auditdata[i].data[j].action_type,
                    "User Type": auditdata[i].data[j].user_type,
                    "Change Date & Time": moment(auditdata[i].data[j].createdAt).format("DD-MM-YYYY h:m a")
                })
            }
        }
        return formatedArr;
    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    searchByMonth = (event) => {
        const value = event.target.value;
        this.setState({ searchMonth: value })
        this.getAuditList({ searchMonth: value });


        // console.log(value)
    }

    render = () => {
        const { info, exportData, fileName, auditList, addressField, searchMonth } = this.state
        return (

            <>

                <section className="landing-profl-detls">
                    <div className="container">
                        <CallCenterHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="landing-page-pt pt-3 pb-4">
                                <div className="container">
                                    <div className="mr-landing-page">
                                        <div className="landing-head-pt">
                                            {/* <h2>Call Center</h2> */}
                                            <h5>Welcome {info ? info.name : ""} to Emrok Application (Admin)</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{" "}

                        {/* Row end*/}
                        <div className="landing-form-dtls">
                            <div className="row mb-25 align-items-center">
                                <div className="col-md-4">
                                    <h5 className="mb-20">Audit Trial Report of All Users Activity</h5>
                                </div>

                                <div className="col-md-8">
                                    <div className="row align-items-center">
                                        <div className="col-md-10">
                                            <div className="d-flex flex-column flex-md-row justify-content-md-end align-items-md-center">
                                                <div className="col-md-3 pe-0 text-md-end">
                                                    <label className="me-3 form-label mb-md-0">Select Month: </label>
                                                </div>
                                                <div className="col-md-5">
                                                    <input className="form-control" type="month" onChange={(event) => this.searchByMonth(event)} value={searchMonth} />
                                                </div>
                                            </div>   
                                        </div>
                                        <div className="col-md-2">
                                            <div className="floatright mt-4 mt-md-0">
                                                <ExportToExcel apiData={exportData} fileName={fileName} />
                                            </div>
                                        </div>
                                    </div>                            
                                </div>
                            </div>
                            <div className="">
                                
                                {auditList.map((item, index) => (

                                    <div className="row" key={item._id}>
                                        <div className="col-md-12 auditdateheading mt-4">
                                            <h6 className="fw-bold">{item._id ? moment(item._id).format("DD-MM-YYYY") : ""}</h6>
                                        </div>
                                        <hr className="horizanlatrule" />
                                        {item.data.map((item1, index1) => (



                                            <div className="row auditrowstyle" key={item1._id}>
                                                <div className="col-md-2">
                                                    {item1.createdAt ? moment(item1.createdAt).format("h:m a") : ""}
                                                </div>

                                                {item1.action_type == "Modify" ? (
                                                    <div className="col-md-10">

                                                        {addressField.find((item) => item.name === item1.change_field) ?
                                                            <p className="messagestyle">{item1.user ? item1.user.name : ""} changed {item1.change_field} of Customer: {item1.customer ? item1.customer.name : ""} ({item1.customer.customer_code}). Old Record: {item1.customer_old_value} , New Record: {item1.customer ? item1.customer.address[item1.change_field] : ""} </p> :

                                                            <p className="messagestyle">{item1.user ? item1.user.name : ""} changed {item1.change_field} of Customer: {item1.customer ? item1.customer.name : ""} ({item1.customer.customer_code}). Old Record: {item1.customer_old_value} , New Record: {item1.customer ? item1.customer[item1.change_field] : ""}</p>

                                                        }
                                                    </div>

                                                ) : (


                                                    item1.action_type == "Login" ? (

                                                        <div className="col-md-10">


                                                            {item1.user_type != "doctor" ? <p className="messagestyle">{item1.user ? item1.user.name : ""} ({item1.user_type == "callcenter" ? "Call Center" : item1.user_type.charAt(0).toUpperCase() + item1.user_type.slice(1)}) Logged in </p> : <p className="messagestyle">{item1.doctor ? item1.doctor.name : ""} (Doctor) Logged in </p>}


                                                        </div>) : (

                                                        <div className="col-md-10">


                                                            <p className="messagestyle">{item1.user ? item1.user.name : ""} ({item1.user_type == "callcenter" ? "Call Center" : item1.user_type.charAt(0).toUpperCase() + item1.user_type.slice(1)}) has {item1.action_type} the order of {item1.customer.name} ({item1.customer.customer_code}) with order number {item1.order?item1.order.order_id:""} for {item1.reject_reason}. </p>


                                                        </div>

                                                    )

                                                )}


                                            </div>

                                        ))}


                                    </div>


                                ))}


                                {auditList.length>0?"":<p className="align-items-center">No Record Found</p>}



                            </div>

                        </div>
                    </div>
                </section>
                <CustomerFooter />



            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const mapStateToProps = ({ AdminReducer, CommonReducer }) => {
    const { admin } = AdminReducer
    const { summaryDetails } = CommonReducer
    return {
        admin,
        summaryDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(Dashboard)))