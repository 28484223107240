import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import MrHeader from "../../../components/layout/MR/MrHeader";
import MrAction from "../../../redux/actions/Mr.action";
import OrderAction from "../../../redux/actions/Order.action";
import MrService from "../../../services/Mr.service";
import OtherService from "../../../services/OtherService";
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CommonAction from "../../../redux/actions/Common.action";
import DropdownDoctor from "../../../components/SearchableDropdown/DropdownDoctor.component";
import Summary from "../../../components/Summary/Summary.component";
import CallCenterService from "../../../services/CallCenter.service";
import { ExportToExcel } from "../../../components/Excel/ExportToExcel";
class MrDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mrdetails: null,
            mrid: "",
            mrdetailsList: [],
            mrinfo: "",
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            summaryCols: ["No of Orders", "Blood Collection Completed", "Recevied at Manufacturing", "Ready to Dispatch", "Received by doctor", "Received by Patient"],
            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "MR_Data",
            cityTAT: [],
        }
    }



    static getDerivedStateFromProps(props, state) {
        return {
            mrdetails: props._id || null,
            summaryDetails: props.summaryDetails || null,
        }
    }

    formatData(auditdata) {
        return new Promise(resolve => {
            try {
                console.log(auditdata, auditdata.length, "auditdata")
                let formatedArr = [];
                let tathrs = 0;
                for (let i = 0; i < auditdata.length; i++) {

                    const patientCityUpperCase = auditdata[i].customer.address.town.toUpperCase();
                    console.log(patientCityUpperCase, "12333333");
                    tathrs = this.state.cityTAT.filter(item => item.city == patientCityUpperCase)
                    console.log(tathrs, 123333);

                    let srlPickedUpDate = auditdata[i].createdAt;
                    // let srlPickedUpDate = "2023-09-29 14:30:00";

                    const dateTimeMoment = moment(srlPickedUpDate, 'YYYY-MM-DD HH:mm:ss');
                    // Add hours to the Moment object
                    const hoursToAdd = tathrs ? (tathrs[0] ? tathrs[0].TAT : 0) : 0; // Change this to the number of hours you want to add
                    const newDateTimeMoment = dateTimeMoment.clone().add(hoursToAdd, 'hours');
                    // Format the new date and time as desired
                    const formattedNewDateTime = newDateTimeMoment.format('YYYY-MM-DD HH:mm:ss'); // Change the format as needed

                    // Get the current date and time
                    const currentDate = new Date();

                    const dispatch_delayed_date_1 = auditdata[i].dispatch_delayed_mail_send_date != '' ? moment(auditdata[i].dispatch_delayed_mail_send_date, 'YYYY-MM-DD HH:mm:ss') : '';
                    const dispatch_date_1 = auditdata[i].dispatch_date != '' ? moment(auditdata[i].dispatch_date, 'YYYY-MM-DD HH:mm:ss') : '';

                    console.log(dispatch_delayed_date_1, 1230);
                    console.log(dispatch_date_1, 1230);

                    const dispatch_delayed_date = dispatch_delayed_date_1;
                    const dispatch_date = dispatch_date_1;

                    const format_dispatch_delayed_date = dispatch_delayed_date != '' ? dispatch_delayed_date.format('YYYY-MM-DD HH:mm:ss') : '';
                    const format_dispatch_date = dispatch_date != '' ? dispatch_date.format('YYYY-MM-DD HH:mm:ss') : '';

                    console.log(format_dispatch_delayed_date, 12301)
                    console.log(format_dispatch_date, 12301)

                    // Convert formattedNewDateTime to a JavaScript Date object for comparison
                    const formattedNewDate = new Date(formattedNewDateTime);

                    const format_dispatch_delayed_date_2 = new Date(format_dispatch_delayed_date);
                    const format_dispatch_date_2 = new Date(format_dispatch_date);
                    let timeDifference = null;
                    if (dispatch_date == '' && auditdata[i].is_delayed_mail_send) {
                        timeDifference = currentDate.getTime() - format_dispatch_delayed_date.getTime();
                    } else if (dispatch_date != '' && auditdata[i].is_delayed_mail_send && format_dispatch_delayed_date_2 != '' && format_dispatch_date_2 != '') {

                        console.log(format_dispatch_delayed_date_2, 12302);
                        console.log(format_dispatch_date_2, 12302);
                        timeDifference = format_dispatch_date_2.getTime() - format_dispatch_delayed_date_2.getTime();
                        // timeDifference = '';
                    }
                    // Calculate the difference in milliseconds
                    let daysDifference = 0
                    if (auditdata[i].is_delayed_mail_send) {
                        // Convert the time difference to days
                        daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                    }


                    const standardhours = tathrs ? (tathrs[0] ? tathrs[0].TAT : 0) : 0;
                    const standardhrs_in_day = standardhours == 0 ? 0 : parseInt(standardhours) / 24;

                    formatedArr.push({
                        "Order No": auditdata[i].order_id || null,
                        "Patient Name": auditdata[i].customer ? auditdata[i].customer?.name : null,
                        "Doctor Name": auditdata[i].doctor ? auditdata[i].doctor?.name : null,
                        "Stockist name": auditdata[i].stockist ? auditdata[i].stockist?.name : null,
                        "Patient State": auditdata[i].customer ? auditdata[i].customer.address.state : null,
                        "Patient City": auditdata[i].customer ? auditdata[i].customer.address.town : null,
                        "Order Date": auditdata[i].createdAt ? moment(auditdata[i].createdAt).format('DD-MM-YYYY') : "",
                        "Blood Collection Date": auditdata[i].collection_date ? moment(auditdata[i].collection_date).format('DD-MM-YYYY') : "",
                        "SRL TAT (Days)": standardhrs_in_day + "(" + (daysDifference != null ? daysDifference : 0) + ")",
                        "Shipment Date": auditdata[i].GRN_DATE ? moment(auditdata[i].GRN_DATE).format('DD-MM-YYYY') : "",
                        "Delivery Date": auditdata[i].is_received_by_doctor_datetime ? moment(auditdata[i].is_received_by_doctor_datetime).format('DD-MM-YYYY') : "",
                        "Status": auditdata[i].orderStatus
                    })
                }
                console.log(formatedArr, "formatedArr");
                return resolve(formatedArr);
            } catch (error) {
                console.log(error, "78778");
                return resolve(null);
            }
        })


    }

    getCityTAT = async () => {
        const alltats = await OtherService.getCitytat();
        if (alltats.data.success) {
            console.log(alltats.data.data, '123tat');
            this.setState({ cityTAT: alltats.data.data });

        }

    }
    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    getSummary = async (mr_id = null) => {
        if (mr_id) {
            // let payload = {
            //     type: "MR",
            //     id: mr_id
            // }
            const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;

            const selectedMomentFrom = moment(fromdate);
            const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
            const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

            const selectedMomentTo = moment(todate);
            const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
            const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');


            let payload = {
                type: "MR",
                id: mr_id,
                fromDate: fromdate ? selectedOnlyDateFrom : "",
                toDate: todate ? selectedOnlyDateTo : "",
                stateName: selectedState,
                town: selectedTown,
                doctor_id: selectedDoctor,
                orderStatus: setorderStatus
            };
            OtherService.getSummary(payload).then(data => {
                console.log(data, "getSummary");
            }).catch(err => {
                console.log(err, "getSummary err");
            })
        }
    }

    clickFn = async (index) => {
        const mrdetails = this.props.mrdetails;
        if (mrdetails) {
            // let payload = await {
            //     mr_id: mrdetails._id
            // }

            const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;

            const selectedMomentFrom = moment(fromdate);
            const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
            const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

            const selectedMomentTo = moment(todate);
            const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
            const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');


            let payload = {
                // type: "MR",
                mr_id: mrdetails._id,
                fromDate: fromdate ? selectedOnlyDateFrom : "",
                toDate: todate ? selectedOnlyDateTo : "",
                stateName: selectedState,
                town: selectedTown,
                doctor_id: selectedDoctor,
            };
            if (index === 1) {
                payload["orderStatus"] = await "blood_coll_completed"
                this.setState({ setorderStatus: "blood_coll_completed" })
            }
            else if (index === 2) {
                payload["orderStatus"] = await "received_at_manufacture"
                this.setState({ setorderStatus: "received_at_manufacture" })
            }
            else if (index === 3) {
                payload["orderStatus"] = await "dispatched"
                this.setState({ setorderStatus: "dispatched" })
            }
            else if (index === 4) {
                payload["orderStatus"] = await "received_by_doc"
                this.setState({ setorderStatus: "received_by_doc" })
            }
            else if (index === 5) {
                payload["orderStatus"] = await "received_by_patient"
                this.setState({ setorderStatus: "received_by_patient" })
            }
            this.getmrDetails(payload)
        }

    }

    getmrDetails = (details) => {
        this.setState({ mrdetailsList: [] }, () => {
            MrService.getmrDetails(details).then(async data => {
                console.log(data.data, "mr details 2");

                if (data.data.success && data.data.data.mrdetails !== '') {
                    console.log(data.data, "okkkkk");

                    // const doctors = data.data.data.mrdetails.map(item => item.doctor);
                    // console.log(doctors, "alldoctors list");

                    const formatedData = await this.formatData(data.data.data.mrdetails);
                    console.log(formatedData, "formatedData")
                    this.setState({ exportData: formatedData });
                    this.setState({ mrdetailsList: data.data.data.mrdetails });

                    // this.setState({ doctorList: doctors });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        });
    }

    componentDidMount() {

        const mrdetails = this.props.mrdetails;
        if (mrdetails) {
            const mr_id = mrdetails._id;
            let details = {
                mr_id: mr_id
            };
            console.log(details, "mr details 1",);


            MrService.getmrInfo(details).then(data => {
                console.log(data.data, "mr info");

                if (data.data.success && data.data.data.mrinfo !== '') {
                    console.log(data.data.data.mrinfo[0], "okkkkk");

                    this.setState({ mrinfo: data.data.data.mrinfo[0] });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })


            this.getmrDetails(details)
            this.getSummary(mr_id)
            this.getCityTAT();
            let detailsBody = {
                id: mr_id,
                type: "mr",
            };
            MrService.getDoctorsByMr(detailsBody).then(data => {
                console.log(data.data, "doctordetailsbymr123");

                if (data.data.success && data.data.data.doctorinfo !== '') {
                    console.log(data.data, "doctorokkk1233");

                    // const doctors = data.data.data.mrdetails.map(item => item.doctor);
                    // console.log(doctors, "alldoctors list");

                    // this.setState({ mrdetailsList: data.data.data.mrdetails });
                    this.getDoctors(data.data.data.doctorinfo);
                    // this.setState({ doctorList: data.data.data.doctorinfo });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })

            setTimeout(() => {
                this.populateStates("IN")
                // this.populateStates(countryObj.isoCode)	
            }, 500);
        } else {


        }



    }

    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {
                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.selectedState || null
                        console.log(_state, this.state.states, "jhgjhgjhg");
                        if (_state) {
                            let stateObj = null
                            for (let i = 0; i < this.state.states.length; i++) {
                                if (this.state.states[i].name === _state) {
                                    stateObj = this.state.states[i]
                                }
                            }
                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })

                    setTimeout(() => {
                        let stateDetails = this.state.states;
                        const allOption = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.states, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll = [allOption, ...stateDetails];
                        console.log(optionsWithAll, "details state all")
                        this.setState({ states: optionsWithAll })
                    }, 500);
                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {

                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))

                    setTimeout(() => {
                        let stateDetails = this.state.towns;
                        const allOption1 = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.towns, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll1 = [allOption1, ...stateDetails];
                        console.log(optionsWithAll1, "details state all")
                        this.setState({ towns: optionsWithAll1 })
                    }, 500);
                }
                // }, 2000);
            })
        }
    }

    setOrderHistoryData = (data) => {
        let resp = []
        let isShowRTObutton = false;

        for (let i = 0; i < data.length; i++) {

            if (data[i].is_received_by_doctor_datetime) {
                // Given date
                const givenDate = new Date(data[i].is_received_by_doctor_datetime); // Replace with your desired date
                // Current date
                const currentDate = new Date();
                // Calculate the difference in milliseconds
                const timeDifference = currentDate - givenDate;
                // Calculate the number of milliseconds in 72 hours
                const hours72InMilliseconds = 72 * 60 * 60 * 1000; // 72 hours * 60 minutes/hour * 60 seconds/minute * 1000 milliseconds/second
                // Check if 72 hours have passed
                if (timeDifference >= hours72InMilliseconds) {
                    isShowRTObutton = true;
                    console.log('72 hours have passed since the given date.');
                } else {
                    isShowRTObutton = false;
                    console.log('72 hours have not passed since the given date.');
                }

            } else {
                isShowRTObutton = false;
            }



            const patientCityUpperCase = data[i].customer.address.town.toUpperCase();
            const tathrs = this.state.cityTAT.filter(item => item.city == patientCityUpperCase)

            let srlPickedUpDate = data[i].createdAt;
            // let srlPickedUpDate = "2023-09-29 14:30:00";

            const dateTimeMoment = moment(srlPickedUpDate, 'YYYY-MM-DD HH:mm:ss');
            // Add hours to the Moment object
            const hoursToAdd = tathrs ? (tathrs[0] ? tathrs[0].TAT : 0) : 0; // Change this to the number of hours you want to add
            const newDateTimeMoment = dateTimeMoment.clone().add(hoursToAdd, 'hours');
            // Format the new date and time as desired
            const formattedNewDateTime = newDateTimeMoment.format('YYYY-MM-DD HH:mm:ss'); // Change the format as needed

            // Get the current date and time
            const currentDate = new Date();

            const dispatch_delayed_date_1 = data[i].dispatch_delayed_mail_send_date != '' ? moment(data[i].dispatch_delayed_mail_send_date, 'YYYY-MM-DD HH:mm:ss') : '';
            const dispatch_date_1 = data[i].dispatch_date != '' ? moment(data[i].dispatch_date, 'YYYY-MM-DD HH:mm:ss') : '';

            console.log(dispatch_delayed_date_1, 1230);
            console.log(dispatch_date_1, 1230);

            const dispatch_delayed_date = dispatch_delayed_date_1;
            const dispatch_date = dispatch_date_1;

            const format_dispatch_delayed_date = dispatch_delayed_date != '' ? dispatch_delayed_date.format('YYYY-MM-DD HH:mm:ss') : '';
            const format_dispatch_date = dispatch_date != '' ? dispatch_date.format('YYYY-MM-DD HH:mm:ss') : '';

            console.log(format_dispatch_delayed_date, 12301)
            console.log(format_dispatch_date, 12301)

            // Convert formattedNewDateTime to a JavaScript Date object for comparison
            const formattedNewDate = new Date(formattedNewDateTime);

            const format_dispatch_delayed_date_2 = new Date(format_dispatch_delayed_date);
            const format_dispatch_date_2 = new Date(format_dispatch_date);
            let timeDifference = null;
            if (dispatch_date == '' && data[i].is_delayed_mail_send) {
                timeDifference = currentDate.getTime() - format_dispatch_delayed_date.getTime();
            } else if (dispatch_date != '' && data[i].is_delayed_mail_send && format_dispatch_delayed_date_2 != '' && format_dispatch_date_2 != '') {

                console.log(format_dispatch_delayed_date_2, 12302);
                console.log(format_dispatch_date_2, 12302);
                timeDifference = format_dispatch_date_2.getTime() - format_dispatch_delayed_date_2.getTime();
                // timeDifference = '';
            }
            // Calculate the difference in milliseconds
            let daysDifference = 0
            if (data[i].is_delayed_mail_send) {
                // Convert the time difference to days
                daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            }


            const standardhours = tathrs ? (tathrs[0] ? tathrs[0].TAT : 0) : 0;
            const standardhrs_in_day = standardhours == 0 ? 0 : parseInt(standardhours) / 24;


            resp.push({
                _id: data[i]._id,
                stockist_name: data[i].stockist.name,
                doctor_name: data[i].doctor.name,
                customer_name: data[i].customer.name,
                customer_state: data[i].customer.address.state,
                customer_town: data[i].customer.address.town,
                customer_mobile: data[i].customer.mobileno,
                collection_date: data[i].collection_date ? data[i].collection_date : "",
                grn_date: data[i].GRN_DATE ? data[i].GRN_DATE : "",
                order_status: data[i].orderStatus ? data[i].orderStatus : "",
                order_date: data[i].createdAt ? data[i].createdAt : "",
                order_id: data[i].order_id,
                isShowRTObutton: isShowRTObutton,
                delivery_date: data[i].is_received_by_doctor_datetime ? data[i].is_received_by_doctor_datetime : "",
                standard_srl_value: standardhrs_in_day,
                actual_srl_value: daysDifference,
                is_delayed_mail_send: data[i].is_delayed_mail_send

            })
        }
        return resp
    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    getDoctors = (doctors) => {
        // Create an array to store the grouped data
        const groupedData = [];
        const finalData = [];

        // Create an object to keep track of seen doctor._id values
        const seenDoctorIds = {};

        // Use forEach to iterate through the data
        doctors.forEach((currentValue) => {
            const doctorId = currentValue._id;

            // Check if we've already seen this doctor._id
            if (!seenDoctorIds[doctorId]) {
                // Add the current value to the groupedData array
                groupedData.push(currentValue);

                // Mark the doctor._id as seen
                seenDoctorIds[doctorId] = true;
            }
        });

        for (let index = 0; index < groupedData.length; index++) {
            finalData[index] = {
                "name": groupedData[index].name,
                "label": groupedData[index].name,
                "value": groupedData[index]._id,
                "id": groupedData[index]._id,
            };


        }


        const allOption = {
            "name": "All",
            "label": "All",
            "value": "All",
            "id": "All"
        };

        let optionsWithAll = [allOption, ...finalData];


        console.log(finalData, "finalData");
        // Now 'groupedData' contains unique doctors with a regular index

        this.setState({ doctorList: optionsWithAll });

        console.log(groupedData, "groupedData")

        // Now 'groupedData' contains the data grouped by doctor._id


    }

    handleTrackOrder = (event) => {

        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        try {
            OtherService.getOrderDetails({ _id: targetId }).then(data => {
                console.log(data.data, "order details 123456789");
                if (data.data.success) {
                    console.log(data.data.data.order, "okkkkk 123456789");
                    this.props.saveSelectedOrders(data.data.data.order)
                    this.props.navigate('/mr/track-info-order');
                    this.props.setUserType('mr');
                    // this.setState({mrdetailsList:data.data.data.mrdetails});
                }
            })
        } catch (error) {

        }

    }

    changeValFromDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });
        this.setState({ todate: "" });


    }
    changeValToDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });

    }

    searchFilter = (event) => {
        this.setState({ mrdetailsList: [] });
        const mrdetails = this.props.mrdetails;
        const mr_id = mrdetails._id;
        const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;

        const selectedMomentFrom = moment(fromdate);
        const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
        // Calculate the next day using moment
        const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
        const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

        const selectedMomentTo = moment(todate);
        const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
        // Calculate the next day using moment
        const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
        const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');


        let details = {
            mr_id: mr_id,
            fromDate: fromdate ? selectedOnlyDateFrom : "",
            toDate: todate ? selectedOnlyDateTo : "",
            stateName: selectedState,
            town: selectedTown,
            doctor_id: selectedDoctor,
            orderStatus: setorderStatus
        };

        if (fromdate != "" && todate == "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else if (fromdate == "" && todate != "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else {

            console.log(details, "details 1234455")
            MrService.getmrDetails(details).then(data => {
                console.log(data.data, "mr details after filter");

                if (data.data.success && data.data.data.mrdetails !== '') {
                    console.log(data.data, "data after filter");

                    this.setState({ mrdetailsList: data.data.data.mrdetails });
                    const formatedData = this.formatData(data.data.data.mrdetails);
                    console.log(formatedData, "formatedData")
                    this.setState({ exportData: formatedData });
                }

                this.getSummary(mr_id)

            }).catch(err => {

                console.log(err, "err")
            })


        }




        console.log("search filter");
    }

    changeDoctor = (event) => {
        const doctorId = event.target.value;
        this.setState({ selectedDoctor: doctorId });
        console.log(doctorId, "doctorId");
    }


    handleRTOAction = (event) => {
        const callCenterDetails = this.props.mrdetails;
        const order_id = event.target.name;
        const selectedOrderDetails = callCenterDetails.filter(item => item.order_id == order_id)

        let details = {

            order_id: order_id,
            user_id: selectedOrderDetails[0]._id,
            customer_id: selectedOrderDetails[0].customer._id,
            user_type: "mr"
        }

        this.props.hoc.customAlert('Are you sure you want to proceed?', true, "", true, true, () => {

            CallCenterService.getInitiateRTO(details).then(data => {

                const mrdetails = this.props.mrdetails;

                const mr_id = mrdetails._id;
                let details = {
                    mr_id: mr_id
                };


                this.getmrDetails(details)

                console.log(data, "alldata");
                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })

            console.log(selectedOrderDetails, "selectedOrderDetails")

        }, () => {

        })




        // alert(order_id);
    }

    render = () => {
        let _data = this.setOrderHistoryData(this.state.mrdetailsList)
        const { countries, states, towns, isLoading, showTowns, selectedState, selectedTown, fromdate, todate, selectedDoctor, doctorList, summaryCols, summaryDetails, exportData, fileName } = this.state;
        // const formatFromDate = moment(fromdate).format('DD-MM-YYYY');
        // const formatToDate = moment(todate).format('DD-MM-YYYY');
        console.log(_data, "mrdetailsListsfsd");
        return (

            <>

                <section className="landing-page-pt landing-profl-detls">
                    <div className="container">
                        <MrHeader />
                        <div className="mr-landing-page mt-20">
                            <div className="landing-head-pt">
                                {/* <h2>MR Landing page</h2> */}
                            </div>
                        </div>
                        <div className="landing-form-dtls mt-10">
                            <h5>Welcome {this.state.mrinfo.name} to MyOwnn Application (MR)</h5>

                            <div className="row mt-30 mb-20 ">
                                <div className="row">
                                    <div className="col-md-6">

                                    </div>

                                    <div className="col-md-6">

                                        <div className="flotright marginright">
                                            <ExportToExcel apiData={exportData} fileName={fileName} />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Order Date From</label>
                                    {/* <input type="date" format="dd-mm-yyyy" value={fromdate} name="fromdate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                    <DatePicker
                                        selected={fromdate}
                                        onChange={(date) => this.changeValFromDate(date)}
                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                    />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Order Date To</label>
                                    {/* <input type="date" format="dd-mm-yyyy" min={this.state.fromdate} value={todate} name="todate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                    <DatePicker
                                        selected={todate}
                                        onChange={(date) => this.changeValToDate(date)}
                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                        minDate={this.state.fromdate}
                                    />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State </label>
                                    {
                                        states.length ?
                                            <Dropdown
                                                items={states}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // let _registration = this.state.registration
                                                        // _registration.address["state"] = item.name
                                                        // _registration.address["town"] = ""
                                                        this.setState({ selectedState: item.name })
                                                        //console.log(item, "item");
                                                        if (item.name != "All") {
                                                            this.populateTowns(item.countryCode, item.isoCode)
                                                        } else {
                                                            this.setState({ towns: [] })
                                                            this.setState({ selectedTown: "" })
                                                        }
                                                    }
                                                }}
                                                value={selectedState}
                                            /> :
                                            <Dropdown
                                                items={states}
                                                value={selectedState}
                                            />
                                    }
                                    {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">City/Town </label>
                                    {
                                        towns.length ?
                                            <Dropdown
                                                items={towns}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedTown: item.name })
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedTown}
                                            /> :
                                            <Dropdown
                                                items={towns}
                                                value={selectedTown}
                                            />
                                    }
                                    {/* <input type="text" value={town} onChange={(event) => this.changeAddr(event)} name="town" id className="form-control name-pt-ft" placeholder /> */}
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Doctors </label>
                                    {/* <select name="doctorname" className="form-control name-pt-ft" onChange={(event) => this.changeDoctor(event)} value={selectedDoctor}>
                                        <option value="">Please Select</option>
                                        {doctorList.length > 0 ? doctorList.map(doctor => (
                                            <option value={doctor._id}>{doctor.name}</option>

                                        )) : <option></option>}

                                    </select> */}

                                    {
                                        doctorList.length ?
                                            <DropdownDoctor
                                                items={doctorList}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedDoctor: item.id })
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedDoctor}
                                            /> :
                                            <DropdownDoctor
                                                items={doctorList}
                                                value={selectedDoctor}
                                            />
                                    }

                                </div>
                                <div className="">
                                    <button type="button" className="landing-success-btn" onClick={(event) => this.searchFilter(event)}>
                                        Search</button>
                                    <button type="button" className="landing-clear-btn" onClick={(event) => window.location.reload()}>
                                        Clear Filter</button>
                                </div>

                            </div>
                            <Summary
                                columns={summaryCols}
                                data={[{
                                    totalOrderCount: summaryDetails ? summaryDetails.totalOrderCount || 0 : 0,
                                    bloodCollectionCompleted: summaryDetails ? summaryDetails.bloodCollectionCompleted || 0 : 0,
                                    totalReceivedAtManufacturing: summaryDetails ? summaryDetails.totalReceivedAtManufacturing || 0 : 0,
                                    totalDispatched: summaryDetails ? summaryDetails.totalDispatched || 0 : 0,
                                    totalReceivedByDoctor: summaryDetails ? summaryDetails.totalReceivedByDoctor || 0 : 0,
                                    totalReceivedByPatient: summaryDetails ? summaryDetails.totalReceivedByPatient || 0 : 0,
                                }]}
                                clickFn={this.clickFn}
                            />

                            <div className="table-responsive">

                                {
                                    _data && _data.length ?
                                        <CustomDatatable
                                            pagination={true}
                                            columns={[
                                                {
                                                    name: 'Order No.',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Patient Name',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Doctor Name',
                                                    // selector: row => row.doctor_name,

                                                },
                                                {
                                                    name: 'Stockist Name',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Patient State',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Patient City',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Order Date',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Blood Collection Date',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'SRL TAT (Days)',
                                                    // selector: row => row.createdAt,

                                                },

                                                {
                                                    name: 'Shipment Date',
                                                    // selector: row => row.orderStatus,

                                                },
                                                {
                                                    name: 'Delivery Date',
                                                    // selector: row => row.orderStatus,

                                                },
                                                // {
                                                //     name: 'Payment Collected by Stockist',
                                                //     // selector: row => row.orderStatus,

                                                // },
                                                {
                                                    name: 'Status',
                                                    // selector: row => row.createdAt,

                                                }
                                            ]}
                                            data={this.setOrderHistoryData(this.state.mrdetailsList)}
                                            handleTrackOrder={this.handleTrackOrder}
                                            handleRTOAction={this.handleRTOAction}
                                            pageName="mrlanding"
                                        />
                                        : null
                                }
                            </div>

                        </div>

                        <CustomerFooter />
                    </div>
                </section>


            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        // dispatching plain actions
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, MrReducer, CommonReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { mrdetails } = MrReducer
    const { userType, summaryDetails } = CommonReducer
    console.log(customer, 123);
    console.log(mrdetails, 123456);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        mrdetails,
        userType,
        summaryDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(MrDetails)))