import React from 'react'
import { BrowserRouter, Outlet, Route, Routes, useRoutes } from 'react-router-dom'
import Login from '../screens/Login/Login.screen';
import Registration from '../screens/Customer/Registration/Registration';
import RegistrationScreenCallCenter from '../screens/CallCenter/List/Registration';
import PageNotFound from '../screens/PageNotFound/PageNotFound.screen';
import OrderPlaced from '../screens/Order/OrderPlaced/OrderPlaced';
import PlaceOrderModal from '../screens/Order/PlaceOrderModal/PlaceOrderModal';
import PlaceOrder from '../screens/Order/PlaceOrder/PlaceOrder';
import { loginGuard, customerLoginGuard, mrLoginGuard, doctorLoginGuard, stockistLoginGuard } from '../middleware/guard';
import OrderInformation from '../screens/Order/OrderInformation/OrderInformation';
import {
  useGuardedRoutes,
  GuardConfigProvider,
  GuardProvider
} from 'react-router-guarded-routes'
import FeedbackScreen from '../screens/Feedback/Feedback.screen';
import FeedbackSubmitScreen from '../screens/Feedback/FeedbackSubmit.screen';
import OrderHistory from '../screens/Order/OrderHistory/OrderHistory.screen';
import OrderHistoryTracking from '../screens/Order/OrderHistory/OrderHistoryTracking.screen';
import TrackOrder from '../screens/Order/TrackOrder/TrackOrder';
import OrderHistoryNew from '../screens/Order/OrderHistory/OrderHistoryNew';
import LoginMR from '../screens/MR/Login/Login.screen';
import MrDetailsScreen from '../screens/MR/List/MrDetails.screen';
import DoctorDetailsScreen from '../screens/Doctor/List/DoctorDetails.screen'
import LoginDoctor from '../screens/Doctor/Login/LoginDoctor.screen';
import TrackOrderIndividual from '../screens/Order/TrackOrder/TrackOrderIndividual';
import TrackingOrder from '../screens/Order/TrackOrder/TrackingOrder';
import LoginBaseScreen from '../screens/Login/LoginBase.screen';
import LoginCustomertrackingScreen from '../screens/Login/LoginCustomerTracking.screen';
import StockistListScreen from '../screens/Stockist/List/StockistList.screen'
import CallCenterListScreen from '../screens/CallCenter/List/CallCenterList.screen'
import ManufacturListScreen from '../screens/Manufactur/List/ManufacturList.screen'
import TrackOrderotpScreen from '../screens/TrackOrderByOtp/TrackOrderotp.screen';
import TrackOrderIndividualByQr from '../screens/TrackOrderByOtp/TrackOrderIndividualByQr';
import TrackInfoOrder from '../screens/Order/TrackOrder/TrackInfoOrder';
import AuditPageScreen from '../screens/CallCenter/List/AuditList.screen';
import AdminDashboardScreen from  '../screens/Admin/Dashboard.screen';

const Test = () => <><div>test</div></>
const RouterArr = () => {
  const routes = [

    {
      path: "/customer/",
      exact: true,

      children: [
        { path: "registration", exact: true, guards: [customerLoginGuard], element: <Registration /> },
        { path: "order-placed", exact: true, guards: [customerLoginGuard], element: <OrderPlaced /> },
        { path: "place-order", exact: true, guards: [customerLoginGuard], element: <PlaceOrder /> },
        { path: "place-order-modal", exact: true, guards: [customerLoginGuard], element: <PlaceOrderModal /> },
        { path: "track-order", exact: true, guards: [customerLoginGuard], element: <TrackOrder /> },
        { path: "order-history-new", exact: true, guards: [customerLoginGuard], element: <OrderHistoryNew /> },
        { path: "order-information", exact: true, guards: [customerLoginGuard], element: <OrderInformation /> },
        { path: "login/:prescribedDoctor", guards: [loginGuard], exact: true, element: <Login /> },
        { path: "feedback-info", exact: true, guards: [customerLoginGuard], element: <FeedbackScreen /> },
        { path: "feedback-submit", exact: true, guards: [customerLoginGuard], element: <FeedbackSubmitScreen /> },
        { path: "orders", exact: true, guards: [customerLoginGuard], element: <OrderHistory /> },
        { path: "order-history", exact: true, guards: [customerLoginGuard], element: <OrderHistoryTracking /> },
        { path: "track-info", exact: true, guards: [customerLoginGuard], element: <TrackOrderIndividual /> },
        { path: "tracking-info", exact: true, guards: [customerLoginGuard], element: <TrackingOrder /> },
        { path: "track-info-individual", exact: true, guards: [customerLoginGuard], element: <TrackOrderIndividualByQr /> },
      ]
    },
    {
      path: "/mr/",
      exact: true,

      children: [
        
        { path: "list", exact: true, guards: [mrLoginGuard], element: <MrDetailsScreen /> },
        { path: "login", guards: [mrLoginGuard], exact: true, element: <LoginMR/> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder/> },
      ]
    },
    {
      path: "/doctor/",
      exact: true,

      children: [
        
        { path: "list", exact: true, guards: [doctorLoginGuard], element: <DoctorDetailsScreen /> },
        { path: "login", guards: [doctorLoginGuard], exact: true, element: <LoginDoctor/> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder/> },
      ]
    },
    {
      path: "/stockist/",
      exact: true,

      children: [
        
        { path: "list", exact: true, guards: [stockistLoginGuard], element: <StockistListScreen /> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder/> },
       
      ]
    },
    {
      path: "/callcenter/",
      exact: true,

      children: [
        
        { path: "list", exact: true, guards: [stockistLoginGuard], element: <CallCenterListScreen /> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder/> },
        { path: "patient-details", guards: [mrLoginGuard], exact: true, element: <RegistrationScreenCallCenter/> },
        { path: "audit-page", guards: [mrLoginGuard], exact: true, element: <AuditPageScreen/> },
       
      ]
    },
    {
      path: "/manufactur/",
      exact: true,

      children: [
        
        { path: "list", exact: true, guards: [stockistLoginGuard], element: <ManufacturListScreen /> },
        { path: "track-info-order", guards: [stockistLoginGuard], exact: true, element: <TrackInfoOrder/> },
       
      ]
    },
    {
      path: "/order/",
      exact: true,

      children: [
        
        { path: ":orderid", exact: true, element: <TrackOrderotpScreen /> },
       
      ]
    },
    {
      path: "/admin/",
      exact: true,

      children: [
        
        { path: "dashboard", exact: true, guards: [mrLoginGuard], element: <AdminDashboardScreen /> },
      ]
    },
    {
      path: "/",
      exact: true,
      element: <LoginBaseScreen />,

      children: [

        { path: "/test", exact: true, element: <Test /> }
      ]
    },
    {
      path: "/login",
      exact: true,
      element: <LoginCustomertrackingScreen />,

      children: [

        // { path: "/test", exact: true, element: <Test /> }
      ]
    },
    {
      path: "*", element: <PageNotFound />
    }
  ];
  // let element = useGuardedRoutes([routes]);

  return useGuardedRoutes(routes);
};




const Router = (props) => {
  return (
    <BrowserRouter>
      <GuardConfigProvider>
        <GuardProvider>
          <RouterArr />
        </GuardProvider>

      </GuardConfigProvider>

      {/* <Routes>
        <Route exact path="/" element={<Login />} />
        <Route element={<PageNotFound />} />
      </Routes>  */}
      {/* <Outlet />*/}
    </BrowserRouter>
  )
}

export default Router