export const CUSTOMER_LOGIN_API = "/customer/myownn/customer-login";
export const GENERATE_OTP = "/myownn/save-otp";
export const MATCH_OTP= "/myownn/match-otp";
export const CUSTOMER_DETAIL = "/customer/myownn/customer-detail";
export const UPDATE_CUSTOMER = "/customer/myownn/update-customer";
export const UPDATE_CUSTOMER_CALLCENTER = "/customer/myownn/update-customer-callcenter";
export const GET_PRODUCT = "/product/myownn/get-myown-product";
export const CREATE_ORDER = "/order/myownn/create";
export const UPLOAD_PRESCRIPTION = "/customer/myownn/prescription-upload";
export const GET_SLOTS_DETAILS = "/srl/myownn/get-slots-by-pincode";
export const SRL_SAMPLE_BOOKING = "/srl/myownn/create-sample-booking";
export const GET_MYOWN_PRODUCT = "/product/myownn/get-myown-product";
export const SRL_RESPONSE_SAVE = "/srl/myownn/save-response";
export const SAVE_SRL_ORDER_STATUS = "/srl/myownn/sample-status";
export const GET_CUSTOMER_OUT = "/sap/make-order-customer-xlsx";
export const GET_CUSTOMER_DETAILS_OUT = "/sap/make-customer-xlsx";
export const GET_ORDER_DETAILS_OUT = "/sap/make-order-xlsx";
export const GET_SRL_SAMPLE_STATUS = "/srl/myownn/get-sample-status";
export const MR_LOGIN_API = "/mr/login";
export const MR_DETAIL = "/mr/list";
export const MR_INFO = "/mr/info";
export const GET_DOCTOR_BY_MR = "/mr/get-doctor-by-mr";
export const DOCTOR_LOGIN_API = "/doctor/login";
export const DOCTOR_DETAIL = "/doctor/details";
export const DOCTOR_INFO = "/doctor/info";
export const DOCTOR_VERIFY_OTP = "/doctor/verify-otp";
export const GET_ORDERS = "/customer/get-orders";
export const SEND_LOGIN_SMS = "/customer/myownn/send-sms";
export const OTHER_LOGIN_API = "/myownn/other-user-login";
export const STOCKIST_INFO = "/stockist/info";
export const STOCKIST_DETAIL = "/stockist/list";
export const CALL_CENTER_INFO = "/callcenter/info";
export const CALL_CENTER_DETAIL = "/callcenter/list";
export const MANUFACTUR_INFO = "/manufactur/info";
export const MANUFACTUR_DETAIL = "/manufactur/list";
export const CHANGE_GRN_STATUS = "/manufactur/change-grn-status";
export const GET_ORDER_DETAILS = "/myownn/get-order-details";
export const CUSTOMER_LOGIN_BY_QR = "/myownn/customer-login-by-qr";
export const UPDATE_DELIVERY_STATUS = "/myownn/update-delivery-status";
export const GET_ORDER_DETAILS_BY_ID = "/myownn/get-order-details-by-id";
export const CREATE_FEEDBACK = "/customer/myownn/create-feedback";
export const FEEDBACK_LIST = "/customer/myownn/feedback-list";
export const CUSTOMER_LOGIN_TRACKING_API = "/customer-login-tracking";
export const GET_SUMMARY = "/default/summary-myownn"
export const GET_AUDIT_LIST = "/callcenter/fetch-audit-list"
export const POST_INITIATE_RTO = "/callcenter/initiate-rto"
export const GET_STATUS_TRACK = "/order/get-status-track-myownn"
export const ADMIN_INFO = "/admin/info"
export const GET_CITY_TAT = "/default/tat-srl-myownn"
