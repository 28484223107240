import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import OrderAction from "../../../redux/actions/Order.action";
import CustomerHeader from "../../../components/layout/CustomerHeader";
import moment from 'moment';
import CustomerService from "../../../services/Customer.service";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";

class OrderHistoryTracking extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            orders: [],
            orderList: [],
            selectedOrders: [],
            allOrders: [],
        }
    }

    static getDerivedStateFromProps(state, props) {
        return {
            orders: props.orders || []
        }
    }

    componentDidMount() {
        const customer = this.props.CustomerReducer.customer;

        if (customer) {

            let details = {
                customer_id: customer._id
            }
            try {
                CustomerService.getOrders(details).then(res => {

                    console.log(res, "orderresponse");
                    if (res && res.data && res.data.success && res.data.data) {


                        if (res.data.data.order.length > 0) {

                            console.log("all orders", res.data.data.order);
                            // this.props.navigate('/customer/track-order')
                            this.props.saveAllOrder(res.data.data.order);
                            this.setState({ orderList: res.data.data.order });

                            const { orderList } = this.state;
                            console.log(orderList, "all orders myownn");

                        } else {

                            this.props.hoc.customAlert('You dont have any order.', false);
                        }

                        console.log(res.data.data.order, "order details");

                    } else {

                        this.props.hoc.customAlert('You dont have any order.', false);
                    }

                })
            } catch (error) {
                console.log(error);
            }


        } else {

        }





        // const allOrders = this.props.OrderReducer.allOrders;




    }
    handleTrackOrderClick = (event) => {

        const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;

        console.log(targetId, "target ID");

        console.log(allOrder, "allOrder details");

        const filteredItem = Object.keys(allOrder)
            .filter(key => allOrder[key]._id === targetId)
            .reduce((result, key) => {
                result[0] = allOrder[key];
                return result;
            }, {});

        //  const filteredItem = allOrder.find(item => item._id === targetId);

        // console.log(allOrder, "all orders details");
        console.log(filteredItem, "filter orders");
        this.setState({ selectedOrders: filteredItem });

        this.props.saveSelectedOrders(filteredItem);


        this.props.navigate('/customer/tracking-info');
    }

    handleClickHome = () => {

        this.props.navigate('/customer/registration');
    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            resp.push({
                _id: data[i]._id,
                order_id: data[i].order_id,
                collection_date: data[i].customer.collection_date ?data[i].customer.collection_date : "",
                address: data[i].customer.address.addressLine1 + "," + data[i].customer.address.town + "," + data[i].customer.address.country + "," + data[i].customer.address.state + "," + data[i].customer.address.pin,
                orderStatus: data[i].orderStatus
            })
        }
        return resp
    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    render = () => {
        const { orderList } = this.state;
        const keysArray = Object.keys(orderList);
        console.log(keysArray, "orderList orderList");
        console.log(orderList, "orderList");
        const allOrders = this.props.OrderReducer.allOrders;
        let _data = this.setOrderHistoryData(this.state.orderList)

        return (
            <>

                <section className="landing-profl-detls">
                    <div className="container">
                        <CustomerHeader page="tracking"/>
                        <div className="row">
                            <div className="landing-page-pt pt-3 pb-4">
                                <div className="container">
                                    <div className="mr-landing-page">
                                        <div className="landing-head-pt">
                                            <h2>Order History</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{" "}
                        {/* Row end*/}
                        <div className="landing-form-dtls">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <div className="title">
                                    <h5 className="mb-0">All Order Details</h5>
                                </div>
                                {/* <div className="btn-home">
                                    <a href="javascript:void(0);" onClick={this.handleClickHome}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                        Home
                                    </a>
                                </div> */}
                            </div>
                            <div className="table-responsive">

                                {/* {
                            _data && _data.length &&
                            <CustomDatatable
                                pagination={true}
                                columns={[
                                    {
                                        name: 'Order ID',
                                        selector: row => row.order_id,

                                    },
                                    {
                                        name: 'Blood Collection Date',
                                        selector: row => row.collection_date,

                                    },
                                    {
                                        name: 'Address',
                                        // selector: row => row.createdAt,

                                    },
                                    {
                                        name: 'Delivery Date',
                                        // selector: row => row.createdAt,

                                    },
                                    {
                                        name: 'Status',
                                        selector: row => row.orderStatus,

                                    },
                                    {
                                        name: 'Track Order',
                                        // selector: row => row.createdAt,

                                    }
                                ]}
                                data={this.setOrderHistoryData(this.state.orderList)}
                                handleTrackOrderClick={this.handleTrackOrderClick}
                                pageName="orderhistory"
                            />
                        } */}

                                {
                                    _data && _data.length &&
                                    <CustomDatatable
                                        pagination={true}
                                        columns={[
                                            {
                                                name: 'Order ID',
                                                selector: row => row.order_id,

                                            },
                                            {
                                                name: 'Blood Collection Date',
                                                selector: row => row.collection_date,

                                            },
                                            {
                                                name: 'Address',
                                                // selector: row => row.createdAt,

                                            },
                                            {
                                                name: 'Delivery Date',
                                                // selector: row => row.createdAt,

                                            },
                                            {
                                                name: 'Status',
                                                selector: row => row.orderStatus,

                                            },
                                            {
                                                name: 'Track Order',
                                                // selector: row => row.createdAt,

                                            }
                                        ]}
                                        data={this.setOrderHistoryData(this.state.orderList)}
                                        handleTrackOrderClick={this.handleTrackOrderClick}
                                        pageName="orderhistory"
                                    />
                                }


                                {/* <table
                                    id="example"
                                    className="table table-striped tbl-chng-pt table-responsive"
                                    style={{ width: "100%" }}
                                >
                                    <thead>
                                        <tr>
                                            <th>Order ID</th>
                                            <th>Blood Collection Date</th>
                                            <th style={{ width: "33.3333%" }}>Address</th>
                                            <th >Delivery Date</th>
                                            <th className="text-center">Status</th>
                                            <th width="12%">Track Order</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {keysArray.map((index) => (
                                            <tr className="landing-frm-load">
                                                <td>ID-{orderList[index].order_id}</td>
                                                <td>{orderList[index].customer.collection_date?moment(orderList[index].customer.collection_date).format('MM-DD-YYYY'):""} </td>
                                                <td>Address-{orderList[index].customer.address.addressLine1 + "," + orderList[index].customer.address.town + "," + orderList[index].customer.address.country + "," + orderList[index].customer.address.state + "," + orderList[index].customer.address.pin}</td>
                                                <td></td>
                                                <td className="text-center">
                                                    <button type="button" className="landing-pending-btn">
                                                        {orderList[index].orderStatus}
                                                    </button>
                                                </td>
                                                <td className="text-center">
                                                    <div className="track-order-btn">
                                                        <a href="javascript:void(0)" onClick={this.handleTrackOrderClick} name={orderList[index]._id}> Track Order </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table> */}
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
        saveAllOrder: (allOrders) => dispatch(OrderAction.saveAllOrder(allOrders)),
    }
}

const mapStateToProps = ({ OrderReducer, CustomerReducer }) => {
    // const { orders } = OrderReducer
    return {
        OrderReducer: OrderReducer,
        CustomerReducer: CustomerReducer,
        // orders: orders
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(OrderHistoryTracking)))