import React from "react";
import LogoutIcon from "../../../assets/Svg/LogoutICon";
import CommonAction from "../../../redux/actions/Common.action";
import OrderAction from "../../../redux/actions/Order.action";
import MrAction from "../../../redux/actions/Mr.action";
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux'
import StockistAction from "../../../redux/actions/Stockist.action";
import CallCenterAction from "../../../redux/actions/CallCenter.action";
import DoctorAction from "../../../redux/actions/Doctor.action";
import ManufacturAction from "../../../redux/actions/Manufactur.action";
export default function CustomerHeader() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mrdetails = useSelector((state)=>state.MrReducer.mrdetails);
    const logout = ()=>{
         console.log(mrdetails, "logout");
         dispatch(ManufacturAction.logout());
         dispatch(MrAction.logout());
         dispatch(CommonAction.logout());
         dispatch(OrderAction.clearState())
         dispatch(StockistAction.logout());
         dispatch(CallCenterAction.logout());
         dispatch(DoctorAction.logout());
        // const id = customer && customer.prescribedDoctor && customer.prescribedDoctor._id;
        const url = "/";
        navigate(url)
    }
    return (
        <>
            <div className="row">
                <div className="w-100 logo-top-box mb-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="logo-wrap">
                            <a href="javascript:void(0);"><img src={require("../../../assets/images/Logo.png")} alt="logo" /></a>
                        </div>
                        <div>
                            <button type="submit" className="btn-logout" onClick={()=>{logout()}}>
                                <LogoutIcon />
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}