import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import MrHeader from "../../../components/layout/MR/MrHeader";
import MrAction from "../../../redux/actions/Mr.action";
import MrService from "../../../services/Mr.service";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import StockistHeader from "../../../components/layout/Stockist/StockistHeader";
import StockistService from "../../../services/Stockist.service";
import OtherService from "../../../services/OtherService";
import OrderAction from "../../../redux/actions/Order.action";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import CommonAction from "../../../redux/actions/Common.action";
import DropdownDoctor from "../../../components/SearchableDropdown/DropdownDoctor.component";
import { id } from "date-fns/locale";
import Summary from "../../../components/Summary/Summary.component";
import { ExportToExcel } from "../../../components/Excel/ExportToExcel";
class StockistList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            stockistdetails: null,
            stockistid: "",
            stockistList: [],
            stockistinfo: "",
            stockistdetailsList: [],
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            summaryCols: ["No of Orders", "Stage Blood Collection Completed", "Recevied at Manufacturing", "Ready to Dispatch", "Received doctor", "Received by Patient"],
            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "Stockist_Data",
            cityTAT:[],
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            stockistdetails: props._id || null,
            summaryDetails: props.summaryDetails || null
        }
    }

    getCityTAT = async () => {
        const alltats = await OtherService.getCitytat();
        if(alltats.data.success){
            console.log(alltats.data.data,'123tat');
            this.setState({cityTAT:alltats.data.data});

        }

}

    formatData(auditdata) {

        console.log(auditdata, auditdata.length, "auditdata")

        let formatedArr = [];
        let allfeedback = [];
        let feedbackString = "";
        let tathrs = 0;
        for (let i = 0; i < auditdata.length; i++) {
                    const patientCityUpperCase = auditdata[i].customer.address.town.toUpperCase();
                    console.log(patientCityUpperCase,"12333333");
                    tathrs = this.state.cityTAT.filter(item => item.city == patientCityUpperCase)
                    console.log(tathrs,123333);

                    let srlPickedUpDate = auditdata[i].createdAt;
                    // let srlPickedUpDate = "2023-09-29 14:30:00";

                    const dateTimeMoment = moment(srlPickedUpDate, 'YYYY-MM-DD HH:mm:ss');
                    // Add hours to the Moment object
                    const hoursToAdd = tathrs ? (tathrs[0] ? tathrs[0].TAT : 0) : 0; // Change this to the number of hours you want to add
                    const newDateTimeMoment = dateTimeMoment.clone().add(hoursToAdd, 'hours');
                    // Format the new date and time as desired
                    const formattedNewDateTime = newDateTimeMoment.format('YYYY-MM-DD HH:mm:ss'); // Change the format as needed

                    // Get the current date and time
                    const currentDate = new Date();

                    const dispatch_delayed_date_1 = auditdata[i].dispatch_delayed_mail_send_date != '' ? moment(auditdata[i].dispatch_delayed_mail_send_date, 'YYYY-MM-DD HH:mm:ss') : '';
                    const dispatch_date_1 = auditdata[i].dispatch_date != '' ? moment(auditdata[i].dispatch_date, 'YYYY-MM-DD HH:mm:ss') : '';

                    console.log(dispatch_delayed_date_1, 1230);
                    console.log(dispatch_date_1, 1230);

                    const dispatch_delayed_date = dispatch_delayed_date_1;
                    const dispatch_date = dispatch_date_1;

                    const format_dispatch_delayed_date = dispatch_delayed_date != '' ? dispatch_delayed_date.format('YYYY-MM-DD HH:mm:ss') : '';
                    const format_dispatch_date = dispatch_date != '' ? dispatch_date.format('YYYY-MM-DD HH:mm:ss') : '';

                    console.log(format_dispatch_delayed_date, 12301)
                    console.log(format_dispatch_date, 12301)

                    // Convert formattedNewDateTime to a JavaScript Date object for comparison
                    const formattedNewDate = new Date(formattedNewDateTime);

                    const format_dispatch_delayed_date_2 = new Date(format_dispatch_delayed_date);
                    const format_dispatch_date_2 = new Date(format_dispatch_date);
                    let timeDifference = null;
                    if (dispatch_date == '' && auditdata[i].is_delayed_mail_send) {
                        timeDifference = currentDate.getTime() - format_dispatch_delayed_date.getTime();
                    } else if (dispatch_date != '' && auditdata[i].is_delayed_mail_send && format_dispatch_delayed_date_2 != '' && format_dispatch_date_2 != '') {

                        console.log(format_dispatch_delayed_date_2, 12302);
                        console.log(format_dispatch_date_2, 12302);
                        timeDifference = format_dispatch_date_2.getTime() - format_dispatch_delayed_date_2.getTime();
                        // timeDifference = '';
                    }
                    // Calculate the difference in milliseconds
                    let daysDifference = 0
                    if (auditdata[i].is_delayed_mail_send) {
                        // Convert the time difference to days
                        daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                    }


                    const standardhours = tathrs ? (tathrs[0] ? tathrs[0].TAT : 0) : 0;
                    const standardhrs_in_day = standardhours == 0 ? 0 : parseInt(standardhours) / 24;

            formatedArr.push({

                "Order No": auditdata[i].order_id,
                "Patient Name": auditdata[i].customer ? auditdata[i].customer?.name : "",
                "MR Name": auditdata[i].mr ? auditdata[i].mr?.name : "",
                "Doctor Name": auditdata[i].doctor ? auditdata[i].doctor?.name : "",
                "Stockist name": auditdata[i].stockist ? auditdata[i].stockist.name : "",
                "Patient State": auditdata[i].customer ? auditdata[i].customer.address.state : "",
                "Patient City": auditdata[i].customer ? auditdata[i].customer.address.town : "",
                "Order Date": auditdata[i].createdAt ? moment(auditdata[i].createdAt).format('DD-MM-YYYY') : "",
                "Blood Collection Date": auditdata[i].collection_date ? moment(auditdata[i].collection_date).format('DD-MM-YYYY') : "",
                "SRL TAT (Days)": standardhrs_in_day + "(" + (daysDifference != null ? daysDifference : 0) + ")",
                "Shipment Date": auditdata[i].GRN_DATE ? moment(auditdata[i].GRN_DATE).format('DD-MM-YYYY') : "",
                "Delivery Date": auditdata[i].is_received_by_doctor_datetime ? moment(auditdata[i].is_received_by_doctor_datetime).format('DD-MM-YYYY') : "",
                "Status": auditdata[i].orderStatus,


            })
        }
        return formatedArr;


    }
    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    getSummary = async (stockist_id = null) => {
        if (stockist_id) {
            // let payload = {
            //     type: "STOCKIST",
            //     id: stockist_id
            // }

            const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;

            const selectedMomentFrom = moment(fromdate);
            const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
            const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

            const selectedMomentTo = moment(todate);
            const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
            const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');


            let payload = {
                type: "STOCKIST",
                id: stockist_id,
                fromDate: fromdate ? selectedOnlyDateFrom : "",
                toDate: todate ? selectedOnlyDateTo : "",
                stateName: selectedState,
                town: selectedTown,
                doctor_id: selectedDoctor,
                orderStatus: setorderStatus
            };
            OtherService.getSummary(payload).then(data => {
                console.log(data, "getSummary");
            }).catch(err => {
                console.log(err, "getSummary err");
            })
        }
    }

    clickFn = async (index) => {
        const stockistdetails = this.props.stockistdetails;
        if (stockistdetails) {
            // let payload = await {
            //     stockist_id: stockistdetails._id
            // }
            const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;

            const selectedMomentFrom = moment(fromdate);
            const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
            const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

            const selectedMomentTo = moment(todate);
            const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
            const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');


            let payload = {
                
                stockist_id: stockistdetails._id,
                fromDate: fromdate ? selectedOnlyDateFrom : "",
                toDate: todate ? selectedOnlyDateTo : "",
                stateName: selectedState,
                town: selectedTown,
                doctor_id: selectedDoctor,
                orderStatus: setorderStatus
            };

            if (index === 1) {
                payload["orderStatus"] = await "blood_coll_completed"
                this.setState({ setorderStatus: "blood_coll_completed" })
            }
            else if (index === 2) {
                payload["orderStatus"] = await "received_at_manufacture"
                this.setState({ setorderStatus: "received_at_manufacture" })
            }
            else if (index === 3) {
                payload["orderStatus"] = await "dispatched"
                this.setState({ setorderStatus: "dispatched" })
            }
            else if (index === 4) {
                payload["orderStatus"] = await "received_by_doc"
                this.setState({ setorderStatus: "received_by_doc" })
            }
            else if (index === 5) {
                payload["orderStatus"] = await "received_by_patient"
                this.setState({ setorderStatus: "received_by_patient" })
            }
            this.getStockistDetails(payload)
        }

    }

    getStockistDetails = (details) => {
        this.setState({ stockistdetailsList: [] }, () => {
            StockistService.getStockistDetails(details).then(async data => {
                console.log(data.data, "Stockist details 2");

                if (data.data.success && data.data.data.stockistdetails !== '') {
                    console.log(data.data, "okkkkk");

                    this.setState({ stockistdetailsList: data.data.data.stockistdetails });
                    const formatedData = await this.formatData(data.data.data.stockistdetails);
                    console.log(formatedData, "formatedData")
                    this.setState({ exportData: formatedData });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        })
    }

    componentDidMount() {

        const stockistdetails = this.props.stockistdetails;
        if (stockistdetails) {
            const stockist_id = stockistdetails._id;
            let details = {
                stockist_id: stockist_id
            };
            console.log(details, "stockist details 1",);
            StockistService.getStockistInfo(details).then(data => {
                console.log(data.data, "stockist info");

                if (data.data.success && data.data.data.stockistinfo !== '') {
                    console.log(data.data.data.stockistinfo[0], "okkkkk");

                    this.setState({ stockistinfo: data.data.data.stockistinfo[0] });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })


            this.getStockistDetails(details)
            this.getSummary(stockist_id)
            this.getCityTAT();
            let detailsBody = {
                id: stockist_id,
                type: "stockist",
            };

            MrService.getDoctorsByMr(detailsBody).then(data => {
                console.log(data.data, "doctordetailsbymr123");

                if (data.data.success && data.data.data.doctorinfo !== '') {
                    console.log(data.data, "doctorokkk1233");

                    // const doctors = data.data.data.mrdetails.map(item => item.doctor);
                    // console.log(doctors, "alldoctors list");
                    this.getDoctors(data.data.data.doctorinfo);
                    // this.setState({ mrdetailsList: data.data.data.mrdetails });
                    // this.setState({ doctorList: data.data.data.doctorinfo });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })

            setTimeout(() => {
                this.populateStates("IN")
                // this.populateStates(countryObj.isoCode)	
            }, 500);
        } else {


        }



    }

    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {
                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.selectedState || null
                        console.log(_state, this.state.states, "jhgjhgjhg");
                        if (_state) {
                            let stateObj = null
                            for (let i = 0; i < this.state.states.length; i++) {
                                if (this.state.states[i].name === _state) {
                                    stateObj = this.state.states[i]
                                }
                            }
                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })

                    setTimeout(() => {
                        let stateDetails = this.state.states;
                        const allOption = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.states, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll = [allOption, ...stateDetails];
                        console.log(optionsWithAll, "details state all")
                        this.setState({ states: optionsWithAll })
                    }, 500);
                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))

                    setTimeout(() => {
                        let stateDetails = this.state.towns;
                        const allOption1 = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.towns, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll1 = [allOption1, ...stateDetails];
                        console.log(optionsWithAll1, "details state all")
                        this.setState({ towns: optionsWithAll1 })
                    }, 500);
                }
                // }, 2000);
            })
        }
    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {

            const patientCityUpperCase = data[i].customer.address.town.toUpperCase();
            const tathrs = this.state.cityTAT.filter(item => item.city == patientCityUpperCase)

            let srlPickedUpDate = data[i].createdAt;
            // let srlPickedUpDate = "2023-09-29 14:30:00";

            const dateTimeMoment = moment(srlPickedUpDate, 'YYYY-MM-DD HH:mm:ss');
            // Add hours to the Moment object
            const hoursToAdd = tathrs?(tathrs[0]?tathrs[0].TAT:0):0; // Change this to the number of hours you want to add
            const newDateTimeMoment = dateTimeMoment.clone().add(hoursToAdd, 'hours');
            // Format the new date and time as desired
            const formattedNewDateTime = newDateTimeMoment.format('YYYY-MM-DD HH:mm:ss'); // Change the format as needed

            // Get the current date and time
            const currentDate = new Date();

            const dispatch_delayed_date_1 =  data[i].dispatch_delayed_mail_send_date!=''?moment(data[i].dispatch_delayed_mail_send_date, 'YYYY-MM-DD HH:mm:ss'):'';
            const dispatch_date_1 =  data[i].dispatch_date!=''?moment(data[i].dispatch_date, 'YYYY-MM-DD HH:mm:ss'):'';

            console.log(dispatch_delayed_date_1,1230);
            console.log(dispatch_date_1,1230);

            const dispatch_delayed_date = dispatch_delayed_date_1;
            const dispatch_date = dispatch_date_1;
            
            const format_dispatch_delayed_date = dispatch_delayed_date!=''?dispatch_delayed_date.format('YYYY-MM-DD HH:mm:ss'):'';
            const format_dispatch_date = dispatch_date!=''?dispatch_date.format('YYYY-MM-DD HH:mm:ss'):'';

            console.log(format_dispatch_delayed_date,12301)
            console.log(format_dispatch_date,12301)

            // Convert formattedNewDateTime to a JavaScript Date object for comparison
            const formattedNewDate = new Date(formattedNewDateTime);

            const format_dispatch_delayed_date_2 = new Date(format_dispatch_delayed_date);
            const format_dispatch_date_2 = new Date(format_dispatch_date); 
            let timeDifference = null;
            if(dispatch_date=='' && data[i].is_delayed_mail_send){
                 timeDifference = currentDate.getTime() - format_dispatch_delayed_date.getTime();
            }else if(dispatch_date!='' && data[i].is_delayed_mail_send && format_dispatch_delayed_date_2!='' && format_dispatch_date_2!=''){

                console.log(format_dispatch_delayed_date_2,12302);
                console.log(format_dispatch_date_2,12302);
                timeDifference =  format_dispatch_date_2.getTime() - format_dispatch_delayed_date_2.getTime();
                // timeDifference = '';
            }
        // Calculate the difference in milliseconds
            let daysDifference = 0
        if(data[i].is_delayed_mail_send){
             // Convert the time difference to days
        daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        }

       
        const standardhours = tathrs?(tathrs[0]?tathrs[0].TAT:0):0;
        const standardhrs_in_day = standardhours==0?0: parseInt(standardhours)/24;



            resp.push({
                _id: data[i]._id,
                mr_name: data[i].mr.name,
                doctor_name: data[i].doctor.name,
                customer_name: data[i].customer.name,
                customer_mobile: data[i].customer.mobileno,
                customer_address: data[i].customer.address.addressLine1 + "," + data[i].customer.address.town + "," + data[i].customer.address.state + "," + data[i].customer.address.country + "," + data[i].customer.address.pin,
                customer_state: data[i].customer.address.state,
                customer_town: data[i].customer.address.town,
                order_date: data[i].createdAt ? data[i].createdAt : "",
                order_id: data[i].order_id,
                grn_date: data[i].GRN_DATE ? data[i].GRN_DATE : "",
                order_status: data[i].orderStatus ? data[i].orderStatus : "",
                collection_date: data[i].collection_date ? data[i].collection_date : "",
                delivery_date: data[i].is_received_by_doctor_datetime ? data[i].is_received_by_doctor_datetime : "",
                standard_srl_value:standardhrs_in_day,
                actual_srl_value:daysDifference,
                is_delayed_mail_send:data[i].is_delayed_mail_send

            })
        }
        return resp
    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    getDoctors = (doctors) => {
        // Create an array to store the grouped data
        const groupedData = [];
        const finalData = [];

        // Create an object to keep track of seen doctor._id values
        const seenDoctorIds = {};

        // Use forEach to iterate through the data
        doctors.forEach((currentValue) => {
            const doctorId = currentValue._id;

            // Check if we've already seen this doctor._id
            if (!seenDoctorIds[doctorId]) {
                // Add the current value to the groupedData array
                groupedData.push(currentValue);

                // Mark the doctor._id as seen
                seenDoctorIds[doctorId] = true;
            }
        });

        for (let index = 0; index < groupedData.length; index++) {
            finalData[index] = {
                "name": groupedData[index].name,
                "label": groupedData[index].name,
                "value": groupedData[index]._id,
                "id": groupedData[index]._id,
            };


        }


        const allOption = {
            "name": "All",
            "label": "All",
            "value": "All",
            "id": "All"
        };

        let optionsWithAll = [allOption, ...finalData];


        console.log(finalData, "finalData");
        // Now 'groupedData' contains unique doctors with a regular index

        this.setState({ doctorList: optionsWithAll });

        console.log(groupedData, "groupedData")

        // Now 'groupedData' contains the data grouped by doctor._id


    }

    handleTrackOrder = (event) => {

        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        try {
            OtherService.getOrderDetails({ _id: targetId }).then(data => {
                console.log(data.data, "order details 123456789");
                if (data.data.success) {
                    console.log(data.data.data.order, "okkkkk 123456789");

                    this.props.saveIndividualOrders(data.data.data.order)
                    this.props.setUserType('stockist');

                    //setTimeout(() => {
                    this.props.navigate('/stockist/track-info-order');
                    // }, 1000);
                    // const selectedOrders = this.props.OrderReducer;
                    // console.log(selectedOrders,"selectedOrders");


                    // this.setState({mrdetailsList:data.data.data.mrdetails});
                }
            })
        } catch (error) {

        }

    }
    changeValFromDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });
        this.setState({ todate: "" });
    }

    changeValToDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });
    }

    searchFilter = (event) => {
        this.setState({ stockistdetailsList: [] });
        const stockistdetails = this.props.stockistdetails;
        const stockist_id = stockistdetails._id;
        const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;

        const selectedMomentFrom = moment(fromdate);
        const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
        // Calculate the next day using moment
        const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
        const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

        const selectedMomentTo = moment(todate);
        const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
        // Calculate the next day using moment
        const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
        const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');


        let details = {
            stockist_id: stockist_id,
            fromDate: fromdate ? selectedOnlyDateFrom : "",
            toDate: todate ? selectedOnlyDateTo : "",
            stateName: selectedState,
            town: selectedTown,
            doctor_id: selectedDoctor,
            orderStatus: setorderStatus
        };

        if (fromdate != "" && todate == "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else if (fromdate == "" && todate != "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else {

            StockistService.getStockistDetails(details).then(async data => {
                console.log(data.data, "Stockist details filter");

                if (data.data.success && data.data.data.stockistdetails !== '') {
                    console.log(data.data, "okkkkk filter");

                    this.setState({ stockistdetailsList: data.data.data.stockistdetails });
                    const formatedData = await this.formatData(data.data.data.stockistdetails);
                    console.log(formatedData, "formatedData")
                    this.setState({ exportData: formatedData });
                }

                this.getSummary(stockist_id)



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        }




        console.log("search filter");
    }

    changeDoctor = (event) => {
        const doctorId = event.target.value;
        this.setState({ selectedDoctor: doctorId });
        console.log(doctorId, "doctorId");
    }

    render = () => {
        let _data = this.setOrderHistoryData(this.state.stockistdetailsList)
        const { countries, states, towns, isLoading, showTowns, selectedState, selectedTown, fromdate, todate, doctorList, selectedDoctor, summaryCols, summaryDetails, exportData, fileName } = this.state;

        console.log(this.state.mrdetailsList, "mrdetailsListsfsd", summaryDetails);
        return (

            <>

                <section className="landing-profl-detls">
                    <div className="container">
                        <StockistHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="landing-page-pt pt-3 pb-4">
                                <div className="container">
                                    <div className="mr-landing-page">
                                        <div className="landing-head-pt">
                                            {/* <h2>Stockist Landing page</h2> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{" "}
                        {/* Row end*/}
                        <div className="landing-form-dtls">

                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-20">Welcome {this.state.stockistinfo.name} to MyOwnn Application (Stockist)</h5>
                                </div>

                                <div className="col-md-6">

                                    <div className="flotright marginright">
                                        <ExportToExcel apiData={exportData} fileName={fileName} />
                                    </div>
                                </div>

                            </div>
                            <div className="row mt-30 mb-20 ">
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Order Date From</label>
                                    {/* <input type="date" format="dd-mm-yyyy" value={fromdate} name="fromdate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                    <DatePicker
                                        selected={fromdate}
                                        onChange={(date) => this.changeValFromDate(date)}
                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                    />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Order Date To</label>
                                    {/* <input type="date" format="dd-mm-yyyy" min={this.state.fromdate} value={todate} name="todate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                    <DatePicker
                                        selected={todate}
                                        onChange={(date) => this.changeValToDate(date)}
                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                        minDate={this.state.fromdate}
                                    />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State </label>
                                    {
                                        states.length ?
                                            <Dropdown
                                                items={states}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // let _registration = this.state.registration
                                                        // _registration.address["state"] = item.name
                                                        // _registration.address["town"] = ""
                                                        this.setState({ selectedState: item.name })
                                                        //console.log(item, "item");
                                                        if (item.name != "All") {
                                                            this.populateTowns(item.countryCode, item.isoCode)
                                                        } else {
                                                            this.setState({ towns: [] })
                                                            this.setState({ selectedTown: "" })
                                                        }
                                                    }
                                                }}
                                                value={selectedState}
                                            /> :
                                            <Dropdown
                                                items={states}
                                                value={selectedState}
                                            />
                                    }
                                    {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">City/Town </label>
                                    {
                                        towns.length ?
                                            <Dropdown
                                                items={towns}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedTown: item.name })
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedTown}
                                            /> :
                                            <Dropdown
                                                items={towns}
                                                value={selectedTown}
                                            />
                                    }
                                    {/* <input type="text" value={town} onChange={(event) => this.changeAddr(event)} name="town" id className="form-control name-pt-ft" placeholder /> */}
                                </div>

                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Doctors </label>
                                    {/* <select name="doctorname" className="form-control name-pt-ft" onChange={(event) => this.changeDoctor(event)} value={selectedDoctor}>
                                        <option value="">Please Select</option>
                                        {doctorList.length > 0 ? doctorList.map(doctor => (
                                            <option value={doctor._id}>{doctor.name}</option>

                                        )) : <option></option>}

                                    </select> */}

                                    {
                                        doctorList.length ?
                                            <DropdownDoctor
                                                items={doctorList}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedDoctor: item.id })
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedDoctor}
                                            /> :
                                            <DropdownDoctor
                                                items={doctorList}
                                                value={selectedDoctor}
                                            />
                                    }

                                </div>
                                <div className="">
                                    <button type="button" className="landing-success-btn" onClick={(event) => this.searchFilter(event)}>
                                        Search</button>
                                        <button type="button" className="landing-clear-btn" onClick={(event) => window.location.reload()}>
                                        Clear Filter</button>
                                </div>

                            </div>
                            <Summary
                                columns={summaryCols}
                                data={[{
                                    totalOrderCount: summaryDetails ? summaryDetails.totalOrderCount || 0 : 0,
                                    bloodCollectionCompleted: summaryDetails ? summaryDetails.bloodCollectionCompleted || 0 : 0,
                                    totalReceivedAtManufacturing: summaryDetails ? summaryDetails.totalReceivedAtManufacturing || 0 : 0,
                                    totalDispatched: summaryDetails ? summaryDetails.totalDispatched || 0 : 0,
                                    totalReceivedByDoctor: summaryDetails ? summaryDetails.totalReceivedByDoctor || 0 : 0,
                                    totalReceivedByPatient: summaryDetails ? summaryDetails.totalReceivedByPatient || 0 : 0,
                                }]}
                                clickFn={this.clickFn}
                            />
                            {/* Summary table start */}
                            {/* <div className="summary-table mt-32 mb-4">
                                <h5 className="mb-20">Summary</h5>
                                <div className="table-responsive">
                                    <table className="table table-bordered-custom">
                                        <thead>
                                            <tr>
                                                <th scope="col">No of Orders</th>
                                                <th scope="col">Stage Blood Collection Completed</th>
                                                <th scope="col">Recevied - Manufacturing</th>
                                                <th scope="col">Dispatched</th>
                                                <th scope="col">Received doctor</th>
                                                <th scope="col">Received by Patient</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Lorem</td>
                                                <td>Lorem</td>
                                                <td>Lorem</td>
                                                <td>Lorem</td>
                                                <td>Lorem</td>
                                                <td>Lorem</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div> */}
                            {/* Summary table end */}
                            <h5 className="mb-20">Details</h5>
                            <div className="table-responsive">
                                {
                                    _data && _data.length ?
                                        <CustomDatatable
                                            pagination={true}
                                            columns={[
                                                {
                                                    name: 'Order No.',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'MR Name',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Patient Name',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Doctor Name',
                                                    // selector: row => row.doctor_name,

                                                },
                                                {
                                                    name: 'Patient State',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Patient City',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Order Date',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Blood Collection Date',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'SRL TAT (Days)',
                                                    // selector: row => row.createdAt,

                                                },

                                                {
                                                    name: 'Shipment Date',
                                                    // selector: row => row.orderStatus,

                                                },
                                                {
                                                    name: 'Delivery Date',
                                                    // selector: row => row.orderStatus,

                                                },
                                                // {
                                                //     name: 'Payment Collected by Stockist (Y/N)',
                                                //     // selector: row => row.orderStatus,

                                                // },
                                                {
                                                    name: 'Status',
                                                    // selector: row => row.createdAt,

                                                }
                                            ]}
                                            data={this.setOrderHistoryData(this.state.stockistdetailsList)}
                                            handleTrackOrder={this.handleTrackOrder}
                                            pageName="stockistlanding"
                                        />
                                        : <p className="text-center">No Records Found</p>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <CustomerFooter />



            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        // dispatching plain actions
        // setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails))
        saveIndividualOrders: (selectedOrders) => dispatch(OrderAction.saveIndividualOrders(selectedOrders))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, StockistReducer, CommonReducer }) => {
    const { customer } = CustomerReducer
    const { orders, selectedOrders } = OrderReducer
    const { stockistdetails } = StockistReducer
    const { summaryDetails } = CommonReducer

    console.log(customer, 123);
    console.log(stockistdetails, 123456);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        stockistdetails,
        summaryDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(StockistList)))