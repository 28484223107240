import React from "react";
import LogoutIcon from "../../assets/Svg/LogoutICon";
import CommonAction from "../../redux/actions/Common.action";
import OrderAction from "../../redux/actions/Order.action";
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux'
import CustomerAction from "../../redux/actions/Customer.action";
import DoctorAction from "../../redux/actions/Doctor.action";
import ManufacturAction from "../../redux/actions/Manufactur.action";
import MrAction from "../../redux/actions/Mr.action";
import StockistAction from "../../redux/actions/Stockist.action";
import CallCenterAction from "../../redux/actions/CallCenter.action";
export default function CustomerHeader(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customer = useSelector((state)=>state.CustomerReducer.customer);
    const logout = ()=>{
        
        dispatch(CommonAction.logout());
        dispatch(OrderAction.clearState());
        dispatch(CustomerAction.clearState());
        dispatch(DoctorAction.logout());
        dispatch(ManufacturAction.logout());
        dispatch(MrAction.logout());
        dispatch(CommonAction.logout());
        dispatch(StockistAction.logout());
        dispatch(CallCenterAction.logout());
        if(props.page && props.page=='tracking'){
           
            const url = "/login/";
            navigate(url)
        }else{
            const id = customer && customer.prescribedDoctor && customer.prescribedDoctor._id;
            const url = "/customer/login/"+id;
            id && navigate(url)
        }
      
    }
    return (
        <>
            <div className="row">
                <div className="w-100 logo-top-box mb-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="logo-wrap">
                            {/* changes */}
                            <a href="index.html"><img src={require("../../assets/images/Logo.png")} alt="logo" /></a>
                        </div>
                        <div>
                            <button type="submit" className="btn-logout" onClick={()=>{logout()}}>
                                <LogoutIcon />
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}