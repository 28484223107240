let config = Object.assign({})
const production = require('./env.config').production

Object.defineProperties(config, {
  apiTimeout: {
    value: production ? 10 * 1000 : 20 * 1000, // 10 or 20 sec
    writable: false
  },
  serviceUrl: {
    value: production ? 'https://devapi.emrok.co.in' : 'http://192.168.0.135:9001',
    // value: production ? 'http://uatapi.emrok.co.in' : 'http://192.168.0.216:9000',
    // value: production ? 'https://api.emrok.co.in' : 'http://192.168.29.105:9000',

    writable: false
  },
  refreshTimeout: {
    value: production ? 60 : 10, //in minutes
    writable: false
  },
  xApiToken: {
    value: "1234567809",
    writable: false
  },
  knowMoreLink: {
    value: "https://myownn.in/",
    writable: false
  },
  feedbackCommentCharLimit: {
    value: 300,
    writable: false
  },
  myownnCustomerSupport: {
    value: '022-40543232',
    writable: false
  },
  bdUrl: {
    value: "https://www.bluedart.com/?",
    writable: false
  }
})
Object.seal(config)

export default config