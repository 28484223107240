import {
  PLACE_ORDER,
  LOGOUT,
  SAVE_ALL_ORDER,
  SAVE_SELECTED_ORDERS,
  CLEAR_ORDER_STATE,
  SET_SRL_DETAILS,
  SAVE_INDIVIDUAL_ORDER
} from '../types/Order.type'

const initState = {
  currentOrder: {},
  orders: [],
  allOrders: [],
  selectedOrders: [],
  srldetails:[],
  individualOrders: []
}

export default (state = initState, action) => {
  const { type, payload } = action
  switch (type) {
    case PLACE_ORDER:
      return Object.assign({}, state, { currentOrder: { ...payload } })
    case SAVE_ALL_ORDER:
      return Object.assign({}, state, { allOrders: { ...payload } })
    case SAVE_SELECTED_ORDERS:
      return Object.assign({}, state, { selectedOrders: { ...payload } })
    case SAVE_INDIVIDUAL_ORDER:
      return Object.assign({}, state, { individualOrders: { ...payload } })
    case SET_SRL_DETAILS:
      return Object.assign({}, state, { srldetails: { ...payload } })
    case CLEAR_ORDER_STATE:
      return Object.assign({}, initState)
      case LOGOUT:
      return initState;

    default:
      return state
  }
}