import React from "react";
import config from "../../config/emrok.config";
export default function CustomerFooter() {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="footer">
                            <div className="d-flex flex-md-row flex-column justify-content-between align-items-center">
                                <div>
                                    <p className="m-0 footer-copyright-text">Copyright © 2023 Wockhardt Regenerative Limited. All Rights Reserved.</p>
                                </div>
                                <div>
                                    <div className="d-flex">
                                        <p className="privacy-policy-text customer_caretext">Customer Care: {config.myownnCustomerSupport}</p>
                                        <a href={`${config.serviceUrl}/images/product_img/privacy_policy.pdf`} className="privacy-policy-text me-2" target="_blank">Privacy Policy</a>
                                        <a href={`${config.serviceUrl}/images/product_img/tems_condition.pdf`} className="privacy-policy-text" target="_blank">Terms &amp; Conditions</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> {/* Footer end*/}
                </div>
            </footer>
        </>
    )
}